import { SetStateAction } from "react";
import { CardDeck, Col, Row } from "reactstrap";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import remarkBreaks from "remark-breaks";
import { ReactGlobals, TreeNode } from "../../Types";
import StructureNode from "../../genericNodeTypes/Structure";

const SRCharacterNode = (
  node: TreeNode,
  globals: ReactGlobals,
  load_node: (new_id: string) => void,
  handleTempContentChange: (event: {
    target: { value: SetStateAction<string> };
  }) => void
) => {
  return (
    <Row key={Math.random().toString() + node.ID}>
      {node.Children.filter((child: TreeNode) => child.Name === "Portrait").map(
        (child: TreeNode) => (
          <Col xs="auto" key={Math.random().toString() + node.ID}>
            <Markdown remarkPlugins={[remarkGfm, remarkBreaks]}>
              {child.Content}
            </Markdown>
          </Col>
        )
      )}
      <Col>
        <Markdown remarkPlugins={[remarkGfm, remarkBreaks]}>
          {node.ID === globals.node.ID
            ? globals.temporaryContent
            : node.Content}
        </Markdown>
      </Col>
      <Row>
        <CardDeck
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: "5px",
          }}
        >
          {node.Children.filter(
            (child: TreeNode) => child.Type === "Structure"
          ).map((child: TreeNode) =>
            StructureNode(child, globals, load_node, handleTempContentChange)
          )}
        </CardDeck>
      </Row>
    </Row>
  );
};

export function add_SRCharacter_to_dicts(
  constuctor_dict: Map<
    string,
    (
      node: TreeNode,
      globals: ReactGlobals,
      load_node: (new_id: string) => void,
      handleTempContentChange: (event: {
        target: { value: SetStateAction<string> };
      }) => void
    ) => JSX.Element
  >,
  type_prompt_dict: Map<string, string[]>,
  type_adding_dict: Map<string, string[]>
) {
  constuctor_dict.set("SR Character", SRCharacterNode);
  type_prompt_dict.set("SR Character", ["Improve Description"]);
  type_adding_dict.set("SR Character", ["Website", "Structure"]);
}

export default SRCharacterNode;

import { Row, Col, ButtonGroup, Button, Input, Spinner } from "reactstrap";
import { ReactGlobals, TreeNode } from "./Types";
import { SetStateAction } from "react";

const cap_length = (name: string) => {
  if (name.length > 12) {
    return name.substring(0, 9) + "...";
  } else {
    return name;
  }
};

const node_header = (
  globals: ReactGlobals,
  load_node: (node_id: string) => void,
  handleNameInputChange: (event: {
    target: {
      value: SetStateAction<string>;
    };
  }) => void
) => {
  return (
    <Row>
      <Col style={{ margin: "5px" }}>
        <ButtonGroup style={globals.editMode ? { width: "100%" } : {}}>
          {globals.node.PathToRoot.slice(-3).map((waypoint: TreeNode) => (
            <Button
              key={Math.random().toString() + globals.node.ID}
              outline
              onClick={() => {
                load_node(waypoint.ID);
              }}
            >
              <div style={{ whiteSpace: "nowrap" }}>
                {cap_length(waypoint.Name)}
              </div>
            </Button>
          ))}
          {globals.editMode ? (
            <Input
              value={globals.temporaryName}
              onChange={handleNameInputChange}
            />
          ) : (
            <Button disabled>{globals.temporaryName}</Button>
          )}
        </ButtonGroup>
        {globals.isConnected ? (
          <></>
        ) : (
          <Spinner size="sm">Connecting...</Spinner>
        )}
      </Col>
    </Row>
  );
};

export default node_header;

import { Input, InputGroup, InputGroupText } from "reactstrap";
import { ReactGlobals, TreeNode } from "../Types";
import backendService from "../../services/backend.service";
import { SetStateAction } from "react";

const BoolNode = (
  node: TreeNode,
  globals: ReactGlobals,
  load_node: (new_id: string) => void,
  handleTempContentChange: (event: {
    target: { value: SetStateAction<string> };
  }) => void
) => {
  return (
    <InputGroup key={Math.random().toString() + node.ID}>
      <InputGroupText>
        <Input
          addon
          type="checkbox"
          id={"checkbox-878a7-" + node.ID}
          checked={
            node.ID === globals.node.ID
              ? globals.temporaryContent === "true"
              : node.Content === "true"
          }
          onChange={() => {}}
          onClick={() => {
            backendService
              .change_content(node.ID, "" + !(node.Content === "true"))
              .then((res) => {
                load_node(globals.node.ID);
              });
          }}
        />
      </InputGroupText>
      <InputGroupText>
        {node.ID === globals.node.ID ? globals.temporaryName : node.Name}
      </InputGroupText>
    </InputGroup>
  );
};

export function add_Bool_to_dicts(
  constuctor_dict: Map<
    string,
    (
      node: TreeNode,
      globals: ReactGlobals,
      load_node: (new_id: string) => void,
      handleTempContentChange: (event: {
        target: { value: SetStateAction<string> };
      }) => void
    ) => JSX.Element
  >,
  type_prompt_dict: Map<string, string[]>,
  type_adding_dict: Map<string, string[]>
) {
  constuctor_dict.set("Bool", BoolNode);
  type_prompt_dict.set("Bool", []);
  type_adding_dict.set("Bool", []);
}

export default BoolNode;

import {
  Button,
  ButtonDropdown,
  ButtonGroup,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Spinner,
} from "reactstrap";
import {
  ReactGlobals,
  TreeNode,
  constuctor_dict,
  type_adding_dict,
  type_prompt_dict,
} from "./Types";
import { toast } from "react-toastify";
import { BACKEND_URL } from "../constants";
import backendService from "../services/backend.service";

const type_list = Array.from(constuctor_dict.keys());

const copy_tree_to_clipboard = (node: TreeNode) => {
  let json_blob = {
    Name: node.Name,
    Type: node.Type,
    Description: node.Content,
    Items: [],
  };

  const recursive_fill = (
    parentJson: {
      Name: string;
      Type: string;
      Description: string;
      Items: {}[];
    },
    node: TreeNode
  ) => {
    node.Children.forEach((child: TreeNode) => {
      let newChild = {
        Name: child.Name,
        Type: child.Type,
        Description: child.Content,
        Items: [],
      };
      recursive_fill(newChild, child);
      parentJson["Items"].push(newChild);
    });
  };

  recursive_fill(json_blob, node);
  return json_blob;
};

const user_header = (
  globals: ReactGlobals,
  send_prompt: (prompt_name: string) => void,
  load_node: (id: string) => void
) => {
  function paste_child_node(pastedText: string, parent_id: string) {
    try {
      const pasted = JSON.parse(pastedText);
      if ("Name" in pasted) {
        backendService
          .create(
            globals.node.ID,
            pasted["Name"],
            "Website",
            pastedText,
            globals.node.ID
          )
          .then((res) => load_node(globals.node.ID));
      }
    } catch (e) {
      console.log(e);
      toast.error("Pasting failed", {
        position: "bottom-center",
      });
    }
  }

  function create_new_child(parent_node: TreeNode, new_type: string) {
    if (new_type === "Bool") {
      const text =
        '{"Name":"bool","Type":"Bool","Description":"false","Items":[]}';
      paste_child_node(text, parent_node.ID);
    } else if (new_type === "Number") {
      const text =
        '{"Name":"number","Type":"Number","Description":"0","Items":[{"Name":"step","Type":"Number","Description":"1","Items":[]}]}';
      paste_child_node(text, parent_node.ID);
    } else if (new_type === "RangedNumber") {
      const text =
        '{"Name":"ranged number","Type":"RangedNumber","Description":"0","Items":[{"Name":"min","Type":"Number","Description":"0","Items":[]},{"Name":"max","Type":"Number","Description":"10","Items":[]},{"Name":"step","Type":"Number","Description":"1","Items":[]}]}';
      paste_child_node(text, parent_node.ID);
    } else if (new_type === "Color") {
      const text =
        '{"Name":"color","Type":"Color","Description":"#ff0000","Items":[]}';
      paste_child_node(text, parent_node.ID);
    } else if (new_type === "Canvas") {
      const json = {
        Name: "canvas",
        Type: "Canvas",
        Description: '{\n"width": "100%",\n"height": "600px"\n}',
        Items: [],
      };
      const text = JSON.stringify(json);
      paste_child_node(text, parent_node.ID);
    } else if (new_type === "MeshStandardMaterial") {
      const json = {
        Name: "material",
        Type: "MeshStandardMaterial",
        Description: '{\n"color": "#ff0000"\n}',
        Items: [],
      };
      const text = JSON.stringify(json);
      paste_child_node(text, parent_node.ID);
    } else if (new_type === "AmbientLight") {
      const json = {
        Name: "ambient light",
        Type: "AmbientLight",
        Description: '{\n"color": "#ffffff",\n "intensity": 1.0\n}',
        Items: [],
      };
      const text = JSON.stringify(json);
      paste_child_node(text, parent_node.ID);
    } else if (new_type === "PointLight") {
      const json = {
        Name: "point light",
        Type: "PointLight",
        Description:
          '{\n"position": [15,15,15],\n"color": "#ffffff",\n"intensity": 1.0,\n"distance": 0,\n"decay": 0.1,\n"castShadows": false\n}',
        Items: [],
      };
      const text = JSON.stringify(json);
      paste_child_node(text, parent_node.ID);
    } else if (new_type === "DirectionalLight") {
      const json = {
        Name: "directional light",
        Type: "DirectionalLight",
        Description:
          '{\n"position": [15,15,15],\n"color": "#ffffff",\n"intensity": 1.0,\n"castShadows": false\n}',
        Items: [],
      };
      const text = JSON.stringify(json);
      paste_child_node(text, parent_node.ID);
    } else if (new_type === "Mesh") {
      const json = {
        Name: "mesh",
        Type: "Mesh",
        Description:
          '{\n"position":[0,0,0],\n"rotation":[0,0,0],\n"scale":[1,1,1]\n}',
        Items: [],
      };
      const text = JSON.stringify(json);
      paste_child_node(text, parent_node.ID);
    } else if (new_type === "Group") {
      const json = {
        Name: "group",
        Type: "Group",
        Description:
          '{\n"position":[0,0,0],\n"rotation":[0,0,0],\n"scale":[1,1,1]\n}',
        Items: [],
      };
      const text = JSON.stringify(json);
      paste_child_node(text, parent_node.ID);
    } else if (new_type === "BoxGeometry") {
      const json = {
        Name: "box geometry",
        Type: "BoxGeometry",
        Description:
          '{\n"width": 1.0,\n"height": 1.0,\n"depth": 1.0,\n"widthSegments": 1,\n"heightSegments": 1,\n"depthSegments": 1\n}',
        Items: [],
      };
      const text = JSON.stringify(json);
      paste_child_node(text, parent_node.ID);
    } else if (new_type === "CapsuleGeometry") {
      const json = {
        Name: "capsule Geometry",
        Type: "CapsuleGeometry",
        Description:
          '{"radius":1,"length":1,"capSegments":4,"radialSegments":8}',
        Items: [],
      };
      const text = JSON.stringify(json);
      paste_child_node(text, parent_node.ID);
    } else if (new_type === "ConeGeometry") {
      const json = {
        Name: "cone geometry",
        Type: "ConeGeometry",
        Description:
          '{\n  "radius": 0.5,\n  "height": 1.0,\n  "radialSegments": 32,\n  "heightSegments": 1,\n  "openEnded": false,\n  "thetaStart": 0.0,\n  "thetaLength": 6.28318530718\n}',
        Items: [],
      };
      const text = JSON.stringify(json);
      paste_child_node(text, parent_node.ID);
    } else if (new_type === "CylinderGeometry") {
      const json = {
        Name: "cylinder geometry",
        Type: "CylinderGeometry",
        Description:
          '{\n"radiusTop":0.5,\n"radiusBottom":0.5,\n"height":1,\n"radialSegments":32,\n"heightSegments":1,\n"openEnded": false,\n"thetaStart":0,\n"thetaLength":6.28318530718\n}',
        Items: [],
      };
      const text = JSON.stringify(json);
      paste_child_node(text, parent_node.ID);
    } else if (new_type === "PlaneGeometry") {
      const json = {
        Name: "plane geometry",
        Type: "PlaneGeometry",
        Description:
          '{\n"width": 10.0,\n"height": 10.0,\n"widthSegments": 1,\n"heightSegments": 1\n}',
        Items: [],
      };
      const text = JSON.stringify(json);
      paste_child_node(text, parent_node.ID);
    } else if (new_type === "SphereGeometry") {
      const json = {
        Name: "sphere geometry",
        Type: "SphereGeometry",
        Description:
          '{\n"radius":1.0,\n"widthSegments":32,\n"heightSegments":16,\n"phiStart":0.0,\n"phiLength":6.28318530718,\n"thetaStart":0.0,\n"thetaLength":3.14159265359\n}',
        Items: [],
      };
      const text = JSON.stringify(json);
      paste_child_node(text, parent_node.ID);
    } else if (new_type === "TorusGeometry") {
      const json = {
        Name: "torus geometry",
        Type: "TorusGeometry",
        Description:
          '{"radius":1,"tube":0.4,"radialSegments":12,"tubularSegments":48,"arc":6.283185307179586}',
        Items: [],
      };
      const text = JSON.stringify(json);
      paste_child_node(text, parent_node.ID);
    } else if (new_type === "SR Character") {
      const json = {
        Name: "New Character",
        Type: "SR Character",
        Description:
          "- Race: Human\n- Sex: Male\n- Age: ~25\n- Height: 1.80m\n- Description: Generic person",
        Items: [
          {
            Name: "Portrait",
            Type: "Website",
            Description:
              "![blabla](https://drive.google.com/thumbnail?id=1hjWgL30ci_SFXlmj1BrYJk0_t6KHlZsA&sz=w300)",
            Items: [],
          },
          {
            Name: "Stats",
            Type: "Structure",
            Description: "",
            Items: [
              {
                Name: "Health",
                Type: "RangedNumber",
                Description: "12",
                Items: [
                  { Name: "max", Type: "Number", Description: "12", Items: [] },
                ],
              },
            ],
          },
          {
            Name: "Attributes",
            Type: "Structure",
            Description: "",
            Items: [
              {
                Name: "Body",
                Type: "RangedNumber",
                Description: "3",
                Items: [
                  { Name: "max", Type: "Number", Description: "6", Items: [] },
                ],
              },
              {
                Name: "Quickness",
                Type: "RangedNumber",
                Description: "3",
                Items: [
                  { Name: "max", Type: "Number", Description: "6", Items: [] },
                ],
              },
              {
                Name: "Strength",
                Type: "RangedNumber",
                Description: "3",
                Items: [
                  { Name: "max", Type: "Number", Description: "6", Items: [] },
                ],
              },
              {
                Name: "Willpower",
                Type: "RangedNumber",
                Description: "3",
                Items: [
                  { Name: "max", Type: "Number", Description: "6", Items: [] },
                ],
              },
              {
                Name: "Intelligence",
                Type: "RangedNumber",
                Description: "3",
                Items: [
                  { Name: "max", Type: "Number", Description: "6", Items: [] },
                ],
              },
              {
                Name: "Charisma",
                Type: "RangedNumber",
                Description: "3",
                Items: [
                  { Name: "max", Type: "Number", Description: "6", Items: [] },
                ],
              },
            ],
          },
          {
            Name: "Skills",
            Type: "Structure",
            Description: "",
            Items: [
              {
                Name: "Firearms",
                Type: "Number",
                Description: "2",
                Items: [
                  { Name: "step", Type: "Number", Description: "1", Items: [] },
                ],
              },
            ],
          },
        ],
      };
      const text = JSON.stringify(json);
      paste_child_node(text, parent_node.ID);
    } else {
      let new_name = "";
      if (!new_type) new_name = "";
      else new_name = new_type[0].toLowerCase() + new_type.slice(1);
      let new_content = "";
      if (["Website", "Structure"].includes(new_type)) {
        new_content = "Text or Markdown for this node";
      }
      backendService.create(
        parent_node.ID,
        new_name,
        new_type,
        new_content,
        parent_node.ID
      );
      //.then((res) => load_node(node.ID));
    }
  }

  let add_list = type_adding_dict.get(globals.node.Type);
  if (add_list === undefined) {
    add_list = [];
  }

  let prompts = type_prompt_dict.get(globals.node.Type);
  if (prompts === undefined) {
    prompts = [];
  }

  const toggleType = () => {
    globals.setTypeDropdownOpen(!globals.typeDropdownOpen);
  };
  const toggleAdd = () => {
    globals.setAddDropdownOpen(!globals.addDropdownOpen);
  };
  const togglepPrompt = () => {
    globals.setPromptDropdownOpen(!globals.promptDropdownOpen);
  };
  const togglepEditMode = () => {
    globals.setEditMode(!globals.editMode);
  };

  return (
    <Row>
      <Col style={{ margin: "5px", width: "100%" }}>
        <ButtonGroup>
          {globals.isLoggedIn ? (
            <>
              <Button
                outline
                title="Log out"
                onClick={() => {
                  backendService.logout(globals.setIsLoggedIn);
                }}
              >
                🔐 {globals.realUserName}
              </Button>
              <Button outline href={BACKEND_URL + "treenodes/"}>
                ☰
              </Button>
            </>
          ) : (
            <Button
              outline
              title="Log in"
              onClick={() => {
                globals.setOpenLoginModal(true);
              }}
            >
              🔓
            </Button>
          )}
        </ButtonGroup>
        &nbsp;
        {globals.isLoggedIn ? (
          <Button
            outline
            title="Toggle edit mode"
            onClick={() => {
              togglepEditMode();
            }}
          >
            {globals.editMode ? <>👀</> : <>✏️</>}
          </Button>
        ) : (
          <></>
        )}
        &nbsp;
        {globals.isLoggedIn &&
        (globals.temporaryName !== globals.node.Name ||
          globals.temporaryContent !== globals.node.Content) ? (
          <ButtonGroup>
            <Button
              color="success"
              title="Save changes"
              onClick={() => {
                if (globals.temporaryName !== globals.node.Name) {
                  backendService
                    .change_name(globals.node.ID, globals.temporaryName)
                    .then((response) => {
                      if (globals.temporaryContent !== globals.node.Content) {
                        backendService.change_content(
                          globals.node.ID,
                          globals.temporaryContent
                        );
                      }
                    });
                } else {
                  if (globals.temporaryContent !== globals.node.Content) {
                    backendService.change_content(
                      globals.node.ID,
                      globals.temporaryContent
                    );
                  }
                }
              }}
            >
              💾
            </Button>
            <Button
              color="danger"
              title="Discard changes"
              onClick={() => {
                globals.setTemporaryContent(globals.node.Content);
                globals.setTemporaryName(globals.node.Name);
              }}
            >
              ❌
            </Button>
          </ButtonGroup>
        ) : (
          <></>
        )}
      </Col>
      <Col align="right" style={{ margin: "5px" }}>
        {globals.editMode ? (
          <ButtonGroup>
            <ButtonDropdown
              toggle={toggleType}
              isOpen={globals.typeDropdownOpen}
              title="Change node type"
            >
              <DropdownToggle caret outline>
                {globals.node.Type}
              </DropdownToggle>
              <DropdownMenu>
                {type_list.map((type_name: String) => (
                  <DropdownItem
                    key={Math.random().toString() + globals.node.ID}
                    onClick={() => {
                      backendService
                        .change_type(globals.node.ID, type_name.toString())
                        .then((res) => {
                          load_node(globals.node.ID);
                        });
                    }}
                  >
                    {type_name}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </ButtonDropdown>

            {add_list.length > 0 ? (
              <ButtonDropdown
                toggle={toggleAdd}
                isOpen={globals.addDropdownOpen}
              >
                <DropdownToggle caret outline title="Add child node">
                  ➕
                </DropdownToggle>
                <DropdownMenu>
                  {add_list.map((add_name: string) => (
                    <DropdownItem
                      key={Math.random().toString() + globals.node.ID}
                      onClick={() => {
                        create_new_child(globals.node, add_name);
                      }}
                    >
                      {add_name}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </ButtonDropdown>
            ) : (
              <></>
            )}

            <ButtonDropdown
              toggle={togglepPrompt}
              isOpen={globals.promptDropdownOpen}
            >
              <DropdownToggle caret outline title="LLM assistent">
                {globals.isWaitingForAI ? (
                  <Spinner size="sm">Loading...</Spinner>
                ) : (
                  <>🤖</>
                )}
              </DropdownToggle>
              <DropdownMenu>
                {prompts
                  .filter(
                    (prompt_name) =>
                      prompt_name.substring(0, "Translate to ".length) !==
                      "Translate to "
                  )
                  .map((prompt_name: string) => (
                    <DropdownItem
                      key={Math.random().toString() + globals.node.ID}
                      onClick={() => {
                        send_prompt(prompt_name);
                      }}
                    >
                      {prompt_name}
                    </DropdownItem>
                  ))}

                <DropdownItem
                  onClick={() => {
                    globals.setOpenEmojiModal(true);
                  }}
                >
                  Translate to ...
                </DropdownItem>
              </DropdownMenu>
            </ButtonDropdown>
            <Button
              outline
              title="Copy to clipboard"
              onClick={() => {
                let text = copy_tree_to_clipboard(globals.node);
                navigator.clipboard.writeText(JSON.stringify(text));
                toast.success("Node copied to clipboard", {
                  position: "bottom-center",
                });
              }}
            >
              📋
            </Button>
            <Button
              outline
              title="Paste from clipboard"
              onClick={() => {
                if (navigator.clipboard !== undefined) {
                  navigator.clipboard.readText().then((clipText) => {
                    paste_child_node(clipText, globals.node.ID);
                  });
                }
              }}
            >
              📥
            </Button>

            <Button
              outline
              color="danger"
              title="Delete node"
              onClick={() => {
                if (globals.node.PathToRoot.length > 0) {
                  let parent: TreeNode =
                    globals.node.PathToRoot[globals.node.PathToRoot.length - 1];
                  const parent_id: string = parent.ID;
                  backendService.delete(globals.node.ID).then((res) => {
                    load_node(parent_id);
                  });
                }
              }}
            >
              🗑️
            </Button>
          </ButtonGroup>
        ) : (
          <></>
        )}
      </Col>
    </Row>
  );
};

export default user_header;

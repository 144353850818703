import { SetStateAction } from "react";
import { Button, Card, CardBody, CardDeck, CardHeader, Row } from "reactstrap";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import remarkBreaks from "remark-breaks";
import { ReactGlobals, TreeNode } from "../../Types";
import SRCharacterNode from "./SRCharacter";

const SRCharactersNode = (
  node: TreeNode,
  globals: ReactGlobals,
  load_node: (new_id: string) => void,
  handleTempContentChange: (event: {
    target: { value: SetStateAction<string> };
  }) => void
) => {
  return (
    <Card key={Math.random().toString() + node.ID}>
      <CardHeader>
        <Button
          outline
          disabled={node.ID === globals.node.ID}
          onClick={() => {
            load_node(node.ID);
          }}
        >
          {node.ID === globals.node.ID ? globals.temporaryName : node.Name}
        </Button>
      </CardHeader>
      <CardBody>
        <Row>
          <Markdown remarkPlugins={[remarkGfm, remarkBreaks]}>
            {node.Content}
          </Markdown>
        </Row>
        <Row>
          <CardDeck
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: "5px",
            }}
          >
            {node.Children.filter(
              (child: TreeNode) => child.Type === "SR Character"
            ).map((child: TreeNode) => (
              <Card
                key={Math.random().toString() + child.ID}
                style={{
                  width: "100%",
                }}
              >
                <CardHeader>
                  <Button
                    onClick={() => {
                      load_node(child.ID);
                    }}
                    outline
                    style={{
                      textAlign: "left",
                    }}
                  >
                    {child.Name}
                  </Button>
                </CardHeader>
                <CardBody>
                  {SRCharacterNode(
                    child,
                    globals,
                    load_node,
                    handleTempContentChange
                  )}
                </CardBody>
              </Card>
            ))}
          </CardDeck>
        </Row>
      </CardBody>
    </Card>
  );
};

export function add_SRCharacters_to_dicts(
  constuctor_dict: Map<
    string,
    (
      node: TreeNode,
      globals: ReactGlobals,
      load_node: (new_id: string) => void,
      handleTempContentChange: (event: {
        target: { value: SetStateAction<string> };
      }) => void
    ) => JSX.Element
  >,
  type_prompt_dict: Map<string, string[]>,
  type_adding_dict: Map<string, string[]>
) {
  constuctor_dict.set("SR Characters", SRCharactersNode);
  type_prompt_dict.set("SR Characters", []);
  type_adding_dict.set("SR Characters", ["SR Character"]);
}

export default SRCharactersNode;

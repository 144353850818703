import { SetStateAction } from "react";
import ReactPlayer from "react-player/lazy";
import { ReactGlobals, TreeNode } from "../Types";
import { Button, Card, CardBody, CardHeader } from "reactstrap";

const PlayerNode = (
  node: TreeNode,
  globals: ReactGlobals,
  load_node: (new_id: string) => void,
  handleTempContentChange: (event: {
    target: { value: SetStateAction<string> };
  }) => void
) => {
  return (
    <Card key={Math.random().toString() + node.ID}>
      <CardHeader>
        <Button
          outline
          disabled={node.ID === globals.node.ID}
          onClick={() => {
            load_node(node.ID);
          }}
        >
          {node.ID === globals.node.ID ? globals.temporaryName : node.Name}
        </Button>
      </CardHeader>
      <CardBody>
        <ReactPlayer url={node.Content} />
      </CardBody>
    </Card>
  );
};

export function add_Player_to_dicts(
  constuctor_dict: Map<
    string,
    (
      node: TreeNode,
      globals: ReactGlobals,
      load_node: (new_id: string) => void,
      handleTempContentChange: (event: {
        target: { value: SetStateAction<string> };
      }) => void
    ) => JSX.Element
  >,
  type_prompt_dict: Map<string, string[]>,
  type_adding_dict: Map<string, string[]>
) {
  constuctor_dict.set("Player", PlayerNode);
  type_prompt_dict.set("Player", []);
  type_adding_dict.set("Player", []);
}

export default PlayerNode;

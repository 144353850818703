import { SetStateAction } from "react";
import {
  Card,
  CardHeader,
  Button,
  CardBody,
  Col,
  Row,
  Input,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { HexColorPicker } from "react-colorful";
import { Color } from "three";
import { Canvas } from "@react-three/fiber";
import { ReactGlobals, TreeNode } from "../Types";
import backendService from "../../services/backend.service";
import { parseMeshStandardMaterial } from "./ThreeParsers";

const MeshStandardMaterialNode = (
  node: TreeNode,
  globals: ReactGlobals,
  load_node: (new_id: string) => void,
  handleTempContentChange: (event: {
    target: { value: SetStateAction<string> };
  }) => void
) => {
  let material =
    node.ID === globals.node.ID
      ? parseMeshStandardMaterial(globals.temporaryContent)
      : parseMeshStandardMaterial(node.Content);

  let color = material.props.color;
  let metalness = material.props.metalness;
  let roughness = material.props.roughness;
  let side = material.props.side;

  let geometry = <sphereGeometry />;

  function save_material() {
    let sideString = "FrontSide";
    if (side === 1) {
      sideString = "BackSide";
    } else if (side === 2) {
      sideString = "DoubleSide";
    }

    let json = {
      color: "#" + color.getHexString(),
      metalness: metalness,
      roughness: roughness,
      side: sideString,
    };
    const text = JSON.stringify(json);

    node.ID === globals.node.ID
      ? globals.setTemporaryContent(text)
      : backendService.change_content(node.ID, text).then((res) => {
          load_node(globals.node.ID);
        });
  }

  return (
    <Card key={Math.random().toString() + node.ID}>
      <CardHeader>
        <Button
          outline
          disabled={node.ID === globals.node.ID}
          onClick={() => {
            load_node(node.ID);
          }}
        >
          {node.ID === globals.node.ID ? globals.temporaryName : node.Name}
        </Button>
      </CardHeader>
      <CardBody>
        <Row>
          <Col xs="auto">
            <div
              style={{
                width: "200px",
                height: "200px",
              }}
            >
              <Canvas camera={{ position: [0.0, 1.5, 1.5] }}>
                <ambientLight />
                <pointLight
                  position={[5.0, 5.0, 5.0]}
                  decay={0.1}
                  intensity={2.0}
                />
                <mesh>
                  {geometry}
                  {material}
                </mesh>
              </Canvas>
            </div>
          </Col>

          <Col>
            <InputGroup size="sm">
              <InputGroupText className="w-25">color</InputGroupText>
              <Input
                disabled={!globals.isLoggedIn}
                style={{ textAlign: "right" }}
                defaultValue={"#" + color.getHexString()}
                maxLength={7}
                size={7}
                onChange={(e) => {
                  let new_value = e.target.value;
                  if (new_value.length === 7 && new_value[0] === "#") {
                    color = new Color(new_value);
                    save_material();
                  }
                }}
              />
              <InputGroupText className="w-50"></InputGroupText>
            </InputGroup>
            <InputGroup size="sm">
              <InputGroupText className="w-25">metalness</InputGroupText>
              <Input
                disabled={!globals.isLoggedIn}
                style={{ textAlign: "right" }}
                defaultValue={metalness}
                onChange={(e) => {
                  metalness = +e.currentTarget.value;
                }}
                onKeyUp={(e) => {
                  if (e.key === "Enter") save_material();
                }}
              />
              <InputGroupText className="w-50">
                {globals.isLoggedIn ? (
                  <Input
                    name="range"
                    type="range"
                    min={0.0}
                    max={1.0}
                    step={0.001}
                    defaultValue={metalness}
                    onChange={(e) => {
                      if (+e.currentTarget.value !== metalness) {
                        metalness = +e.currentTarget.value;
                      }
                    }}
                    onMouseUp={(e) => {
                      save_material();
                    }}
                  />
                ) : (
                  <></>
                )}
              </InputGroupText>
            </InputGroup>

            <InputGroup size="sm">
              <InputGroupText className="w-25">roughness</InputGroupText>
              <Input
                disabled={!globals.isLoggedIn}
                style={{ textAlign: "right" }}
                defaultValue={roughness}
                onChange={(e) => {
                  roughness = +e.currentTarget.value;
                }}
                onKeyUp={(e) => {
                  if (e.key === "Enter") save_material();
                }}
              />
              <InputGroupText className="w-50">
                {globals.isLoggedIn ? (
                  <Input
                    name="range"
                    type="range"
                    min={0.0}
                    max={1.0}
                    step={0.001}
                    defaultValue={roughness}
                    onChange={(e) => {
                      if (+e.currentTarget.value !== roughness) {
                        roughness = +e.currentTarget.value;
                      }
                    }}
                    onMouseUp={(e) => {
                      save_material();
                    }}
                  />
                ) : (
                  <></>
                )}
              </InputGroupText>
            </InputGroup>
            <InputGroup size="sm">
              <InputGroupText className="w-25">side</InputGroupText>
              <InputGroupText>
                <Input
                  disabled={!globals.isLoggedIn}
                  defaultChecked={side === 0}
                  type="radio"
                  name="front"
                  onChange={(e) => {
                    side = 0;
                    save_material();
                  }}
                />
                &nbsp;front
              </InputGroupText>
              <InputGroupText>
                <Input
                  disabled={!globals.isLoggedIn}
                  defaultChecked={side === 1}
                  type="radio"
                  name="back"
                  onChange={(e) => {
                    side = 1;
                    save_material();
                  }}
                />
                &nbsp;back
              </InputGroupText>
              <InputGroupText>
                <Input
                  disabled={!globals.isLoggedIn}
                  defaultChecked={side === 2}
                  type="radio"
                  name="double"
                  onChange={(e) => {
                    side = 2;
                    save_material();
                  }}
                />
                &nbsp;double
              </InputGroupText>
            </InputGroup>
          </Col>

          {globals.isLoggedIn ? (
            <Col xs="auto">
              <HexColorPicker
                color={material.props.color.getHexString()}
                onChange={(new_color) =>
                  material.props.color.set(new Color(new_color))
                }
                onMouseUp={(e) => save_material()}
                onTouchEnd={(e) => save_material()}
              />
            </Col>
          ) : (
            <></>
          )}
        </Row>
      </CardBody>
    </Card>
  );
};

export function add_MeshStandardMaterial_to_dicts(
  constuctor_dict: Map<
    string,
    (
      node: TreeNode,
      globals: ReactGlobals,
      load_node: (new_id: string) => void,
      handleTempContentChange: (event: {
        target: { value: SetStateAction<string> };
      }) => void
    ) => JSX.Element
  >,
  type_prompt_dict: Map<string, string[]>,
  type_adding_dict: Map<string, string[]>
) {
  constuctor_dict.set("MeshStandardMaterial", MeshStandardMaterialNode);
  type_prompt_dict.set("MeshStandardMaterial", []);
  type_adding_dict.set("MeshStandardMaterial", []);
}

export default MeshStandardMaterialNode;

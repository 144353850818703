import { SetStateAction } from "react";
import { Button, Card, CardBody, CardHeader } from "reactstrap";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import remarkBreaks from "remark-breaks";
import { ReactGlobals, TreeNode, node_to_component } from "../Types";

const StructureNode = (
  node: TreeNode,
  globals: ReactGlobals,
  load_node: (new_id: string) => void,
  handleTempContentChange: (event: {
    target: { value: SetStateAction<string> };
  }) => void
) => {
  return (
    <Card key={Math.random().toString() + node.ID}>
      <CardHeader>
        <Button
          outline
          disabled={node.ID === globals.node.ID}
          onClick={() => {
            load_node(node.ID);
          }}
        >
          {node.ID === globals.node.ID ? globals.temporaryName : node.Name}
        </Button>
      </CardHeader>
      <CardBody>
        <Markdown remarkPlugins={[remarkGfm, remarkBreaks]}>
          {node.ID === globals.node.ID
            ? globals.temporaryContent
            : node.Content}
        </Markdown>
        {node.Children.filter((child: TreeNode) =>
          [
            "Bool",
            "Number",
            "RangedNumber",
            "Structure",
            "Player",
            "Canvas",
            "Color",
          ].includes(child.Type)
        ).map((child: TreeNode) =>
          node_to_component(child, globals, load_node, handleTempContentChange)
        )}
      </CardBody>
    </Card>
  );
};

export function add_Structure_to_dicts(
  constuctor_dict: Map<
    string,
    (
      node: TreeNode,
      globals: ReactGlobals,
      load_node: (new_id: string) => void,
      handleTempContentChange: (event: {
        target: { value: SetStateAction<string> };
      }) => void
    ) => JSX.Element
  >,
  type_prompt_dict: Map<string, string[]>,
  type_adding_dict: Map<string, string[]>
) {
  constuctor_dict.set("Structure", StructureNode);
  type_prompt_dict.set("Structure", [
    "Improve",
    "More formal",
    "More casual",
    "More academic",
  ]);
  type_adding_dict.set("Structure", [
    "Structure",
    "Bool",
    "Number",
    "RangedNumber",
    "Color",
    "Player",
    "Canvas",
  ]);
}

export default StructureNode;

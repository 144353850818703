import {
  Color,
  Vector3,
  Euler,
  Shape,
  FrontSide,
  BackSide,
  DoubleSide,
  Side,
} from "three";
import { ReactGlobals, TreeNode } from "../Types";

export function parseArrayToVec3(vecArray: number[]) {
  let vec = new Vector3();
  if (vecArray.length === 3) {
    vec.setX(vecArray[0]);
    vec.setY(vecArray[1]);
    vec.setZ(vecArray[2]);
  }
  return vec;
}

export function parseAmbientLight(node_content: string) {
  let color = new Color(0xffffff);
  let intensity = 1.0;

  let node_args = new Map<string, Color | number>();
  node_args.set("color", color);
  node_args.set("intensity", intensity);

  try {
    const new_args = JSON.parse(node_content);
    if (new_args.hasOwnProperty("color")) {
      node_args.set("color", new Color(new_args["color"]));
    }
    if (new_args.hasOwnProperty("intensity")) {
      node_args.set("intensity", new_args["intensity"]);
    }
  } catch (e) {
    console.log("error parsing json");
  }

  let new_color = node_args.get("color");
  if (new_color instanceof Color) {
    color = new_color;
  }

  let new_intensity = node_args.get("intensity");
  if (typeof new_intensity === "number") {
    intensity = new_intensity;
  }

  return (
    <ambientLight
      key={Math.random()}
      args={[color, intensity]}
      color={color}
      intensity={intensity}
    />
  );
}

export function parsePointLight(node_content: string) {
  let position = [0, 0, 0];
  let color = new Color(0xffffff);
  let intensity = 1.0;
  let distance = 0.0;
  let decay = 2.0;
  let castShadow = false;

  let node_args = new Map<string, number[] | Color | number | boolean>();
  node_args.set("position", position);
  node_args.set("color", color);
  node_args.set("intensity", intensity);
  node_args.set("distance", distance);
  node_args.set("decay", decay);
  node_args.set("castShadow", castShadow);

  try {
    const new_args = JSON.parse(node_content);
    if (new_args.hasOwnProperty("position")) {
      node_args.set("position", new_args["position"]);
    }
    if (new_args.hasOwnProperty("color")) {
      node_args.set("color", new Color(new_args["color"]));
    }
    if (new_args.hasOwnProperty("intensity")) {
      node_args.set("intensity", new_args["intensity"]);
    }
    if (new_args.hasOwnProperty("distance")) {
      node_args.set("distance", new_args["distance"]);
    }
    if (new_args.hasOwnProperty("decay")) {
      node_args.set("decay", new_args["decay"]);
    }
    if (new_args.hasOwnProperty("castShadow")) {
      node_args.set("castShadow", new_args["castShadow"]);
    }
  } catch (e) {
    console.log("error parsing json");
  }

  let new_position = node_args.get("position");
  if (Array.isArray(new_position) && new_position.length === 3) {
    position = new_position;
  }

  let new_color = node_args.get("color");
  if (new_color instanceof Color) {
    color = new_color;
  }

  let new_intensity = node_args.get("intensity");
  if (typeof new_intensity === "number") {
    intensity = new_intensity;
  }

  let new_distance = node_args.get("distance");
  if (typeof new_distance === "number") {
    distance = new_distance;
  }

  let new_decay = node_args.get("decay");
  if (typeof new_decay === "number") {
    decay = new_decay;
  }

  let new_castShadow = node_args.get("castShadow");
  if (typeof new_castShadow === "boolean") {
    castShadow = new_castShadow;
  }

  return (
    <pointLight
      key={Math.random()}
      args={[color, intensity, distance, decay]}
      position={parseArrayToVec3(position)}
      color={color}
      intensity={intensity}
      distance={distance}
      decay={decay}
      castShadow={castShadow}
    />
  );
}

export function parseDirectionalLight(node_content: string) {
  let position = [0, 0, 0];
  let color = new Color(0xffffff);
  let intensity = 1.0;
  let castShadow = false;

  let node_args = new Map<string, number[] | Color | number | boolean>();
  node_args.set("position", position);
  node_args.set("color", color);
  node_args.set("intensity", intensity);
  node_args.set("castShadow", castShadow);

  try {
    const new_args = JSON.parse(node_content);
    if (new_args.hasOwnProperty("position")) {
      node_args.set("position", new_args["position"]);
    }
    if (new_args.hasOwnProperty("color")) {
      node_args.set("color", new Color(new_args["color"]));
    }
    if (new_args.hasOwnProperty("intensity")) {
      node_args.set("intensity", new_args["intensity"]);
    }
    if (new_args.hasOwnProperty("castShadow")) {
      node_args.set("castShadow", new_args["castShadow"]);
    }
  } catch (e) {
    console.log("error parsing json");
  }

  let new_position = node_args.get("position");
  if (Array.isArray(new_position) && new_position.length === 3) {
    position = new_position;
  }

  let new_color = node_args.get("color");
  if (new_color instanceof Color) {
    color = new_color;
  }

  let new_intensity = node_args.get("intensity");
  if (typeof new_intensity === "number") {
    intensity = new_intensity;
  }

  let new_castShadow = node_args.get("castShadow");
  if (typeof new_castShadow === "boolean") {
    castShadow = new_castShadow;
  }

  return (
    <directionalLight
      key={Math.random()}
      args={[color, intensity]}
      position={parseArrayToVec3(position)}
      color={color}
      intensity={intensity}
      castShadow={castShadow}
    />
  );
}

export function parseMeshStandardMaterial(node_content: string) {
  let color = new Color(0xffffff);
  let metalness = 0.0;
  let roughness = 1.0;
  let side: Side = FrontSide;

  try {
    const new_args = JSON.parse(node_content);
    if (new_args.hasOwnProperty("color")) {
      let new_color = new_args["color"];
      if (typeof new_color === "string") {
        color = new Color(new_color);
      }
    }
    if (new_args.hasOwnProperty("metalness")) {
      let new_metalness = new_args["metalness"];
      if (typeof new_metalness === "number") {
        metalness = new_metalness;
      }
    }
    if (new_args.hasOwnProperty("roughness")) {
      let new_roughness = new_args["roughness"];
      if (typeof new_roughness === "number") {
        roughness = new_roughness;
      }
    }
    if (new_args.hasOwnProperty("side")) {
      let new_side = new_args["side"];
      if (typeof new_side === "string") {
        if (new_side === "FrontSide") {
          side = FrontSide;
        } else if (new_side === "BackSide") {
          side = BackSide;
        } else if (new_side === "DoubleSide") {
          side = DoubleSide;
        }
      }
    }
  } catch (e) {
    console.log("error parsing json");
  }

  return (
    <meshStandardMaterial
      color={color}
      metalness={metalness}
      roughness={roughness}
      side={side}
    />
  );
}

export function parseMesh(node: TreeNode, globals: ReactGlobals) {
  let geometry = <boxGeometry />;
  let material = <meshStandardMaterial />;

  let node_args = new Map<string, Vector3>();
  node_args.set("position", new Vector3(0, 0, 0));
  node_args.set("rotation", new Vector3(0, 0, 0));
  node_args.set("scale", new Vector3(1, 1, 1));

  let castShadow = true;
  let receiveShadow = true;

  try {
    const new_args =
      node.ID === globals.node.ID
        ? JSON.parse(globals.temporaryContent)
        : JSON.parse(node.Content);
    ["position", "rotation", "scale"].forEach((prop_name) => {
      if (new_args.hasOwnProperty(prop_name)) {
        node_args.set(prop_name, parseArrayToVec3(new_args[prop_name]));
      }
    });

    if (new_args.hasOwnProperty("castShadow")) {
      castShadow = new_args["castShadow"];
    }

    if (new_args.hasOwnProperty("receiveShadow")) {
      receiveShadow = new_args["receiveShadow"];
    }
  } catch (e) {
    console.log("error parsing json");
  }

  node.Children.forEach((child: TreeNode) => {
    if (child.Type === "BoxGeometry") {
      geometry = parseBoxGeometry(child.Content);
    } else if (child.Type === "SphereGeometry") {
      geometry = parseSphereGeometry(child.Content);
    } else if (child.Type === "ConeGeometry") {
      geometry = parseConeGeometry(child.Content);
    } else if (child.Type === "CylinderGeometry") {
      geometry = parseCylinderGeometry(child.Content);
    } else if (child.Type === "PlaneGeometry") {
      geometry = parsePlaneGeometry(child.Content);
    } else if (child.Type === "CapsuleGeometry") {
      geometry = parseCapsuleGeometry(child.Content);
    } else if (child.Type === "TorusGeometry") {
      geometry = parseTorusGeometry(child.Content);
    } else if (child.Type === "ShapeGeometry") {
      geometry = parseShapeGeometry(child.Content);
    } else if (child.Type === "MeshStandardMaterial") {
      material = parseMeshStandardMaterial(child.Content);
    }
  });

  let euler_rotation = new Euler(
    node_args.get("rotation")!.x,
    node_args.get("rotation")!.y,
    node_args.get("rotation")!.z
  );

  const mesh = (
    <mesh
      key={Math.random() + node.ID}
      position={node_args.get("position")!}
      rotation={euler_rotation}
      scale={node_args.get("scale")!}
      castShadow={castShadow}
      receiveShadow={receiveShadow}
    >
      {geometry}
      {material}
      {node.Children.filter((child: TreeNode) => child.Type === "Mesh").map(
        (child: TreeNode) => parseMesh(child, globals)
      )}
      {node.Children.filter((child: TreeNode) => child.Type === "Group").map(
        (child: TreeNode) => parseGroup(child, globals)
      )}
    </mesh>
  );
  return mesh;
}

export function parseGroup(node: TreeNode, globals: ReactGlobals) {
  let node_args = new Map<string, Vector3>();
  node_args.set("position", new Vector3(0, 0, 0));
  node_args.set("rotation", new Vector3(0, 0, 0));
  node_args.set("scale", new Vector3(1, 1, 1));

  try {
    const new_args =
      node.ID === globals.node.ID
        ? JSON.parse(globals.temporaryContent)
        : JSON.parse(node.Content);
    ["position", "rotation", "scale"].forEach((prop_name) => {
      if (new_args.hasOwnProperty(prop_name)) {
        node_args.set(prop_name, parseArrayToVec3(new_args[prop_name]));
      }
    });
  } catch (e) {
    console.log("error parsing json");
  }

  let euler_rotation = new Euler(
    node_args.get("rotation")!.x,
    node_args.get("rotation")!.y,
    node_args.get("rotation")!.z
  );

  return (
    <group
      key={Math.random() + node.ID}
      position={node_args.get("position")!}
      rotation={euler_rotation}
      scale={node_args.get("scale")!}
    >
      {node.Children.filter((child: TreeNode) => child.Type === "Mesh").map(
        (child: TreeNode) => parseMesh(child, globals)
      )}
      {node.Children.filter((child: TreeNode) => child.Type === "Group").map(
        (child: TreeNode) => parseGroup(child, globals)
      )}
    </group>
  );
}

export function parseBoxGeometry(node_content: string) {
  let geometry_args = new Map<string, number>();
  geometry_args.set("width", 1.0);
  geometry_args.set("height", 1.0);
  geometry_args.set("depth", 1.0);
  geometry_args.set("widthSegments", 1);
  geometry_args.set("heightSegments", 1);
  geometry_args.set("depthSegments", 1);

  try {
    const new_args = JSON.parse(node_content);
    [
      "width",
      "height",
      "depth",
      "widthSegments",
      "heightSegments",
      "depthSegments",
    ].forEach((prop_name) => {
      if (new_args.hasOwnProperty(prop_name)) {
        geometry_args.set(prop_name, new_args[prop_name]);
      }
    });
  } catch (e) {
    console.log("error parsing json");
  }

  return (
    <boxGeometry
      args={[
        geometry_args.get("width"),
        geometry_args.get("height"),
        geometry_args.get("depth"),
        geometry_args.get("widthSegments"),
        geometry_args.get("heightSegments"),
        geometry_args.get("depthSegments"),
      ]}
    />
  );
}

export function parseSphereGeometry(node_content: string) {
  let geometry_args = new Map<string, number>();
  geometry_args.set("radius", 1.0);
  geometry_args.set("widthSegments", 32);
  geometry_args.set("heightSegments", 16);
  geometry_args.set("phiStart", 0.0);
  geometry_args.set("phiLength", Math.PI * 2.0);
  geometry_args.set("thetaStart", 0.0);
  geometry_args.set("thetaLength", Math.PI);

  try {
    const new_args = JSON.parse(node_content);
    [
      "radius",
      "widthSegments",
      "heightSegments",
      "phiStart",
      "phiLength",
      "thetaStart",
      "thetaLength",
    ].forEach((prop_name) => {
      if (new_args.hasOwnProperty(prop_name)) {
        geometry_args.set(prop_name, new_args[prop_name]);
      }
    });
  } catch (e) {
    console.log("error parsing json");
  }

  return (
    <sphereGeometry
      args={[
        geometry_args.get("radius"),
        geometry_args.get("widthSegments"),
        geometry_args.get("heightSegments"),
        geometry_args.get("phiStart"),
        geometry_args.get("phiLength"),
        geometry_args.get("thetaStart"),
        geometry_args.get("thetaLength"),
      ]}
    />
  );
}

export function parseConeGeometry(node_content: string) {
  let radius = 1.0;
  let height = 1.0;
  let radialSegments = 32;
  let heightSegments = 1;
  let openEnded = false;
  let thetaStart = 0.0;
  let thetaLength = Math.PI * 2.0;

  let geometry_args = new Map<string, boolean | number>();
  geometry_args.set("radius", radius);
  geometry_args.set("height", height);
  geometry_args.set("radialSegments", radialSegments);
  geometry_args.set("heightSegments", heightSegments);
  geometry_args.set("openEnded", openEnded);
  geometry_args.set("thetaStart", thetaStart);
  geometry_args.set("thetaLength", thetaLength);

  try {
    const new_args = JSON.parse(node_content);
    [
      "radius",
      "height",
      "radialSegments",
      "heightSegments",
      "openEnded",
      "thetaStart",
      "thetaLength",
    ].forEach((prop_name) => {
      if (new_args.hasOwnProperty(prop_name)) {
        geometry_args.set(prop_name, new_args[prop_name]);
      }
    });
  } catch (e) {
    console.log("error parsing json");
  }

  let new_radius = geometry_args.get("radius");
  if (typeof new_radius === "number") {
    radius = new_radius;
  }

  let new_height = geometry_args.get("height");
  if (typeof new_height === "number") {
    height = new_height;
  }

  let new_radialSegments = geometry_args.get("radialSegments");
  if (typeof new_radialSegments === "number") {
    radialSegments = new_radialSegments;
  }

  let new_heightSegments = geometry_args.get("heightSegments");
  if (typeof new_heightSegments === "number") {
    heightSegments = new_heightSegments;
  }

  let new_openEnded = geometry_args.get("openEnded");
  if (typeof new_openEnded === "boolean") {
    openEnded = new_openEnded;
  }

  let new_thetaStart = geometry_args.get("thetaStart");
  if (typeof new_thetaStart === "number") {
    thetaStart = new_thetaStart;
  }

  let new_thetaLength = geometry_args.get("thetaLength");
  if (typeof new_thetaLength === "number") {
    thetaLength = new_thetaLength;
  }

  return (
    <coneGeometry
      args={[
        radius,
        height,
        radialSegments,
        heightSegments,
        openEnded,
        thetaStart,
        thetaLength,
      ]}
    />
  );
}

export function parseCylinderGeometry(node_content: string) {
  let radiusTop = 1.0;
  let radiusBottom = 1.0;
  let height = 1.0;
  let radialSegments = 32;
  let heightSegments = 1;
  let openEnded = false;
  let thetaStart = 0.0;
  let thetaLength = Math.PI * 2.0;

  let geometry_args = new Map<string, boolean | number>();
  geometry_args.set("radiusTop", radiusTop);
  geometry_args.set("radiusBottom", radiusBottom);
  geometry_args.set("height", height);
  geometry_args.set("radialSegments", radialSegments);
  geometry_args.set("heightSegments", heightSegments);
  geometry_args.set("openEnded", openEnded);
  geometry_args.set("thetaStart", thetaStart);
  geometry_args.set("thetaLength", thetaLength);

  try {
    const new_args = JSON.parse(node_content);
    [
      "radiusTop",
      "radiusBottom",
      "height",
      "radialSegments",
      "heightSegments",
      "openEnded",
      "thetaStart",
      "thetaLength",
    ].forEach((prop_name) => {
      if (new_args.hasOwnProperty(prop_name)) {
        geometry_args.set(prop_name, new_args[prop_name]);
      }
    });
  } catch (e) {
    console.log("error parsing json");
  }

  let new_radiusTop = geometry_args.get("radiusTop");
  if (typeof new_radiusTop === "number") {
    radiusTop = new_radiusTop;
  }

  let new_radiusBottom = geometry_args.get("radiusBottom");
  if (typeof new_radiusBottom === "number") {
    radiusBottom = new_radiusBottom;
  }

  let new_height = geometry_args.get("height");
  if (typeof new_height === "number") {
    height = new_height;
  }

  let new_radialSegments = geometry_args.get("radialSegments");
  if (typeof new_radialSegments === "number") {
    radialSegments = new_radialSegments;
  }

  let new_heightSegments = geometry_args.get("heightSegments");
  if (typeof new_heightSegments === "number") {
    heightSegments = new_heightSegments;
  }

  let new_openEnded = geometry_args.get("openEnded");
  if (typeof new_openEnded === "boolean") {
    openEnded = new_openEnded;
  }

  let new_thetaStart = geometry_args.get("thetaStart");
  if (typeof new_thetaStart === "number") {
    thetaStart = new_thetaStart;
  }

  let new_thetaLength = geometry_args.get("thetaLength");
  if (typeof new_thetaLength === "number") {
    thetaLength = new_thetaLength;
  }

  return (
    <cylinderGeometry
      args={[
        radiusTop,
        radiusBottom,
        height,
        radialSegments,
        heightSegments,
        openEnded,
        thetaStart,
        thetaLength,
      ]}
    />
  );
}

export function parsePlaneGeometry(node_content: string) {
  let geometry_args = new Map<string, number>();
  geometry_args.set("width", 1.0);
  geometry_args.set("height", 1.0);
  geometry_args.set("widthSegments", 1);
  geometry_args.set("heightSegments", 1);

  try {
    const new_args = JSON.parse(node_content);
    ["width", "height", "widthSegments", "heightSegments"].forEach(
      (prop_name) => {
        if (new_args.hasOwnProperty(prop_name)) {
          geometry_args.set(prop_name, new_args[prop_name]);
        }
      }
    );
  } catch (e) {
    console.log("error parsing json");
  }

  return (
    <planeGeometry
      args={[
        geometry_args.get("width"),
        geometry_args.get("height"),
        geometry_args.get("widthSegments"),
        geometry_args.get("heightSegments"),
      ]}
    />
  );
}

export function parseCapsuleGeometry(node_content: string) {
  let geometry_args = new Map<string, number>();
  geometry_args.set("radius", 1.0);
  geometry_args.set("length", 1.0);
  geometry_args.set("capSegments", 4);
  geometry_args.set("radialSegments", 8);

  try {
    const new_args = JSON.parse(node_content);
    ["radius", "length", "capSegments", "radialSegments"].forEach(
      (prop_name) => {
        if (new_args.hasOwnProperty(prop_name)) {
          geometry_args.set(prop_name, new_args[prop_name]);
        }
      }
    );
  } catch (e) {
    console.log("error parsing json");
  }

  return (
    <capsuleGeometry
      args={[
        geometry_args.get("radius"),
        geometry_args.get("length"),
        geometry_args.get("capSegments"),
        geometry_args.get("radialSegments"),
      ]}
    />
  );
}

export function parseTorusGeometry(node_content: string) {
  let geometry_args = new Map<string, number>();
  geometry_args.set("radius", 1.0);
  geometry_args.set("tube", 0.4);
  geometry_args.set("radialSegments", 12);
  geometry_args.set("tubularSegments", 48);
  geometry_args.set("arc", 2.0 * Math.PI);

  try {
    const new_args = JSON.parse(node_content);
    ["radius", "tube", "radialSegments", "tubularSegments", "arc"].forEach(
      (prop_name) => {
        if (new_args.hasOwnProperty(prop_name)) {
          geometry_args.set(prop_name, new_args[prop_name]);
        }
      }
    );
  } catch (e) {
    console.log("error parsing json");
  }

  return (
    <torusGeometry
      args={[
        geometry_args.get("radius"),
        geometry_args.get("tube"),
        geometry_args.get("radialSegments"),
        geometry_args.get("tubularSegments"),
        geometry_args.get("arc"),
      ]}
    />
  );
}

function parseShape(new_shapes: any[]) {
  const shape = new Shape();
  new_shapes.forEach((command) => {
    if (command[0] === "moveTo") {
      shape.moveTo(command[1], command[2]);
    } else if (command[0] === "bezierCurveTo") {
      shape.bezierCurveTo(
        command[1],
        command[2],
        command[3],
        command[4],
        command[5],
        command[6]
      );
    } else if (command[0] === "lineTo") {
      shape.lineTo(command[1], command[2]);
    }
  });

  /*
Here are multiple examples of 2D shapes

Heart:
  {"shapes":[
["moveTo", 5, 5],
["bezierCurveTo",5, 5, 4, 0, 0, 0],
  ["bezierCurveTo",-6, 0, -6, 7, -6, 7],
  ["bezierCurveTo",-6, 11, -3, 15.4, 5, 19],
  ["bezierCurveTo",12, 15.4, 16, 11, 16, 7],
  ["bezierCurveTo",16, 7, 16, 0, 10, 0],
  ["bezierCurveTo",7, 0, 5, 5, 5, 5]
],"curveSegments":12}

Rounded Square:
{"shapes":[
  ["moveTo", 7, -10],
  ["bezierCurveTo", 8.34, -10, 10, -8.34, 10, -7],
  ["bezierCurveTo", 10, -7, 10, 7, 10, 7],
  ["bezierCurveTo", 10, 8.66, 8.34, 10, 7, 10],
  ["bezierCurveTo", 7, 10, -7, 10, -7, 10],
  ["bezierCurveTo", -8.66, 10, -10, 8.66, -10, 7],
  ["bezierCurveTo", -10, 7, -10, -7, -10, -7],
  ["bezierCurveTo", -10, -8.66, -8.66, -10, -7, -10],
  ["bezierCurveTo", -7, -10, 7, -10, 7, -10]
],"curveSegments":12}

Quater circle:
{"shapes":[
  ["moveTo", 1, 1],
["lineTo", 1, -1],
["lineTo", -1, -1],
["bezierCurveTo", -1, 0, 0, 1, 1, 1]
],"curveSegments":12}

Cross:
{
  "shapes": [
    ["moveTo", -2, 10],
    ["lineTo", 2, 10],
    ["lineTo", 2, 2],
    ["lineTo", 10, 2],
    ["lineTo", 10, -2],
    ["lineTo", 2, -2],
    ["lineTo", 2, -10],
    ["lineTo", -2, -10],
    ["lineTo", -2, -2],
    ["lineTo", -10, -2],
    ["lineTo", -10, 2],
    ["lineTo", -2, 2],
    ["lineTo", -2, 10]
  ],
  "curveSegments": 12
}

5-pointed Star:
{
  "shapes": [
    ["moveTo", 0, -10],
    ["lineTo", 2.94, -4.05],
    ["lineTo", 9.51, -3.09],
    ["lineTo", 4.76, 1.55],
    ["lineTo", 5.88, 8.09],
    ["lineTo", 0, 5],
    ["lineTo", -5.88, 8.09],
    ["lineTo", -4.76, 1.55],
    ["lineTo", -9.51, -3.09],
    ["lineTo", -2.94, -4.05],
    ["lineTo", 0, -10]
  ],
  "curveSegments": 12
}

Arrow:
{
  "shapes": [
    ["moveTo", -10, -2],
    ["lineTo", -10, 2],
    ["lineTo", 5, 2],
    ["lineTo", 5, 4],
    ["lineTo", 15, 0],
    ["lineTo", 5, -4],
    ["lineTo", 5, -2],
    ["lineTo", -10, -2]
  ],
  "curveSegments": 12
}

8-pointed star:
{
  "shapes": [
    ["moveTo", 0, -10],
    ["lineTo", 1.9134, -4.6194],
    ["lineTo", 7.0711, -7.0711],
    ["lineTo", 4.6194, -1.9134],
    ["lineTo", 10, 0],
    ["lineTo", 4.6194, 1.9134],
    ["lineTo", 7.0711, 7.0711],
    ["lineTo", 1.9134, 4.6194],
    ["lineTo", 0, 10],
    ["lineTo", -1.9134, 4.6194],
    ["lineTo", -7.0711, 7.0711],
    ["lineTo", -4.6194, 1.9134],
    ["lineTo", -10, 0],
    ["lineTo", -4.6194, -1.9134],
    ["lineTo", -7.0711, -7.0711],
    ["lineTo", -1.9134, -4.6194],
    ["lineTo", 0, -10]
  ],
  "curveSegments": 12
}

The bezierCurveTo has two control-points followed by the destination Point.

Please create the shape of a footprint
  */

  return shape;
}

export function parseShapeGeometry(node_content: string) {
  let shapes: Shape | Shape[] = new Shape();
  let curveSegments = 12;

  try {
    const new_args = JSON.parse(node_content);
    let new_curveSegments = new_args["curveSegments"];
    if (typeof new_curveSegments === "number") {
      curveSegments = new_curveSegments;
    }
    let new_shapes = new_args["shapes"];
    if (Array.isArray(new_shapes)) {
      if (Array.isArray(new_shapes[0])) {
        if (Array.isArray(new_shapes[0][0])) {
          shapes = new_shapes.map((shape) => parseShape(shape));
        } else {
          shapes = parseShape(new_shapes);
        }
      }
    }
  } catch (e) {
    console.log("error parsing json", e);
  }

  return <shapeGeometry args={[shapes, curveSegments]} />;
}

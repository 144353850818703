import { SetStateAction } from "react";
import {
  Card,
  CardHeader,
  Button,
  CardBody,
  Col,
  Row,
  Input,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { HexColorPicker } from "react-colorful";
import { Color } from "three";
import { Canvas } from "@react-three/fiber";
import { ReactGlobals, TreeNode } from "../Types";
import backendService from "../../services/backend.service";
import { parseDirectionalLight } from "./ThreeParsers";
import { vector3GUI } from "./ThreeGUIElements";

const DirectionalLightNode = (
  node: TreeNode,
  globals: ReactGlobals,
  load_node: (new_id: string) => void,
  handleTempContentChange: (event: {
    target: { value: SetStateAction<string> };
  }) => void
) => {
  let light =
    node.ID === globals.node.ID
      ? parseDirectionalLight(globals.temporaryContent)
      : parseDirectionalLight(node.Content);

  let color = light.props.args[0];
  let castShadow = light.props.castShadow;
  let geometry = <sphereGeometry />;

  function save_node() {
    let json = {
      color: "#" + color.getHexString(),
      intensity: light.props.args[1],
      castShadow: castShadow,
      position: [
        light.props.position.x,
        light.props.position.y,
        light.props.position.z,
      ],
    };
    const text = JSON.stringify(json);

    node.ID === globals.node.ID
      ? globals.setTemporaryContent(text)
      : backendService.change_content(node.ID, text);
  }

  return (
    <Card key={Math.random().toString() + node.ID}>
      <CardHeader>
        <Button
          outline
          disabled={node.ID === globals.node.ID}
          onClick={() => {
            load_node(node.ID);
          }}
        >
          {node.ID === globals.node.ID ? globals.temporaryName : node.Name}
        </Button>
      </CardHeader>
      <CardBody>
        <Row>
          <Col xs="auto">
            <div
              style={{
                width: "200px",
                height: "200px",
              }}
            >
              <Canvas camera={{ position: [0.0, 1.0, 1.5] }}>
                {light}
                <mesh>
                  {geometry}
                  <meshStandardMaterial />
                </mesh>
              </Canvas>
            </div>
          </Col>

          <Col>
            <InputGroup size="sm">
              <InputGroupText className="w-25">color</InputGroupText>
              <Input
                disabled={!globals.isLoggedIn}
                style={{ textAlign: "right" }}
                defaultValue={"#" + color.getHexString()}
                maxLength={7}
                size={7}
                onChange={(e) => {
                  let new_value = e.target.value;
                  if (new_value.length === 7 && new_value[0] === "#") {
                    color = new Color(new_value);
                    save_node();
                  }
                }}
              />
              <InputGroupText className="w-50"></InputGroupText>
            </InputGroup>
            <InputGroup size="sm">
              <InputGroupText className="w-25">intensity</InputGroupText>
              <Input
                disabled={!globals.isLoggedIn}
                style={{ textAlign: "right" }}
                defaultValue={light.props.args[1]}
                onChange={(e) => {
                  light.props.args[1] = +e.currentTarget.value;
                }}
                onKeyUp={(e) => {
                  if (e.key === "Enter") save_node();
                }}
              />
              <InputGroupText className="w-50">
                {globals.isLoggedIn ? (
                  <Input
                    name="range"
                    type="range"
                    min={0.0}
                    max={10.0}
                    step={0.001}
                    defaultValue={light.props.args[1]}
                    onChange={(e) => {
                      if (+e.currentTarget.value !== light.props.args[1]) {
                        light.props.args[1] = +e.currentTarget.value;
                      }
                    }}
                    onMouseUp={(e) => {
                      save_node();
                    }}
                  />
                ) : (
                  <></>
                )}
              </InputGroupText>
            </InputGroup>
            <InputGroup size="sm">
              <InputGroupText className="w-25">castShadow</InputGroupText>
              <InputGroupText>
                <Input
                  disabled={!globals.isLoggedIn}
                  checked={castShadow}
                  type="checkbox"
                  onChange={(e) => {
                    castShadow = e.currentTarget.checked;
                    save_node();
                  }}
                />
              </InputGroupText>
            </InputGroup>
            {vector3GUI(
              "position",
              light.props.position,
              -100.0,
              100.0,
              save_node,
              globals
            )}
          </Col>

          {globals.isLoggedIn ? (
            <Col xs="auto">
              <HexColorPicker
                color={color.getHexString()}
                onChange={(new_color) => {
                  color = new Color(new_color);
                  light.props.color.set(color);
                }}
                onMouseUp={(e) => save_node()}
                onTouchEnd={(e) => save_node()}
              />
            </Col>
          ) : (
            <></>
          )}
        </Row>
      </CardBody>
    </Card>
  );
};

export function add_DirectionalLight_to_dicts(
  constuctor_dict: Map<
    string,
    (
      node: TreeNode,
      globals: ReactGlobals,
      load_node: (new_id: string) => void,
      handleTempContentChange: (event: {
        target: { value: SetStateAction<string> };
      }) => void
    ) => JSX.Element
  >,
  type_prompt_dict: Map<string, string[]>,
  type_adding_dict: Map<string, string[]>
) {
  constuctor_dict.set("DirectionalLight", DirectionalLightNode);
  type_prompt_dict.set("DirectionalLight", []);
  type_adding_dict.set("DirectionalLight", []);
}

export default DirectionalLightNode;

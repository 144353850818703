import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Picker from "@emoji-mart/react";
import data from "@emoji-mart/data";
import { ReactGlobals } from "./Types";

const emoji_modal = (
  globals: ReactGlobals,
  send_prompt: (prompt_name: string) => void
) => {
  return (
    <Modal
      isOpen={globals.openEmojiModal}
      toggle={() => globals.setOpenEmojiModal(!globals.openEmojiModal)}
    >
      <ModalHeader
        toggle={() => globals.setOpenEmojiModal(!globals.openEmojiModal)}
      >
        Translate to ...
      </ModalHeader>
      <ModalBody>
        <Picker
          data={data}
          categories={[
            "flags",
            "frequent",
            "people",
            "nature",
            "foods",
            "activity",
            "places",
            "objects",
            "symbols",
          ]}
          theme="light"
          onEmojiSelect={(e: any) => {
            send_prompt("Translate to " + e.native);
            globals.setOpenEmojiModal(false);
          }}
        />
      </ModalBody>
    </Modal>
  );
};

export default emoji_modal;

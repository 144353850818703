import { SetStateAction } from "react";
import { Button, Label } from "reactstrap";
import { HexColorPicker } from "react-colorful";
import { ReactGlobals, TreeNode } from "../Types";
import backendService from "../../services/backend.service";

const ColorNode = (
  node: TreeNode,
  globals: ReactGlobals,
  load_node: (new_id: string) => void,
  handleTempContentChange: (event: {
    target: { value: SetStateAction<string> };
  }) => void
) => {
  let color =
    node.ID === globals.node.ID ? globals.temporaryContent : node.Content;

  return globals.isLoggedIn ? (
    <div key={Math.random().toString() + node.ID}>
      <Label size="sm">
        {node.ID === globals.node.ID ? globals.temporaryName : node.Name}:{" "}
        {color}
      </Label>
      <HexColorPicker
        color={color}
        onChange={(new_color) => (color = new_color)}
        onMouseUp={(e) =>
          backendService.change_content(node.ID, color).then((res) => {
            load_node(globals.node.ID);
          })
        }
        onTouchEnd={(e) =>
          backendService.change_content(node.ID, color).then((res) => {
            load_node(globals.node.ID);
          })
        }
      />
      <br />
    </div>
  ) : (
    <div key={Math.random().toString() + node.ID}>
      <Label size="sm">color: {color}&nbsp;</Label>
      <Button disabled>
        <div style={{ backgroundColor: color }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </div>
      </Button>
      <br />
    </div>
  );
};

export function add_Color_to_dicts(
  constuctor_dict: Map<
    string,
    (
      node: TreeNode,
      globals: ReactGlobals,
      load_node: (new_id: string) => void,
      handleTempContentChange: (event: {
        target: { value: SetStateAction<string> };
      }) => void
    ) => JSX.Element
  >,
  type_prompt_dict: Map<string, string[]>,
  type_adding_dict: Map<string, string[]>
) {
  constuctor_dict.set("Color", ColorNode);
  type_prompt_dict.set("Color", []);
  type_adding_dict.set("Color", []);
}

export default ColorNode;

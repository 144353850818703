import { SetStateAction } from "react";
import { Button, ButtonGroup, CardDeck, Col, Row } from "reactstrap";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import remarkBreaks from "remark-breaks";
import { ReactGlobals, TreeNode, node_to_component } from "../Types";

const WebsiteNode = (
  node: TreeNode,
  globals: ReactGlobals,
  load_node: (new_id: string) => void,
  handleTempContentChange: (event: {
    target: { value: SetStateAction<string> };
  }) => void
) => {
  return (
    <>
      <Row>
        <Col>
          <Markdown remarkPlugins={[remarkGfm, remarkBreaks]}>
            {globals.temporaryContent}
          </Markdown>
        </Col>
        <Col xs="auto">
          <ButtonGroup vertical>
            {node.Children.filter((child: TreeNode) =>
              ["Website","Canvas"].includes(child.Type)
            ).map((child: TreeNode) => (
              <Button
                key={Math.random().toString() + node.ID}
                outline
                style={{
                  textAlign: "left",
                }}
                onClick={() => {
                  load_node(child.ID);
                }}
              >
                {child.Name.length > 30
                  ? child.Name.substring(0, 28) + "..."
                  : child.Name}
              </Button>
            ))}
          </ButtonGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          {node.Children.filter((child: TreeNode) =>
            ["Player", "Structure", "SR Characters"].includes(
              child.Type
            )
          ).length > 0 ? (
            <CardDeck
              style={{
                display: "flex",
                flexDirection: "column",
                flexWrap: "wrap",
                gap: "5px",
              }}
            >
              {node.Children.filter((child: TreeNode) =>
                ["Canvas", "Player", "Structure", "SR Characters"].includes(
                  child.Type
                )
              ).map((child: TreeNode) =>
                node_to_component(
                  child,
                  globals,
                  load_node,
                  handleTempContentChange
                )
              )}
            </CardDeck>
          ) : (
            <></>
          )}
        </Col>
      </Row>
    </>
  );
};

export function add_Website_to_dicts(
  constuctor_dict: Map<
    string,
    (
      node: TreeNode,
      globals: ReactGlobals,
      load_node: (new_id: string) => void,
      handleTempContentChange: (event: {
        target: { value: SetStateAction<string> };
      }) => void
    ) => JSX.Element
  >,
  type_prompt_dict: Map<string, string[]>,
  type_adding_dict: Map<string, string[]>
) {
  constuctor_dict.set("Website", WebsiteNode);
  type_prompt_dict.set("Website", [
    "Improve",
    "More formal",
    "More casual",
    "More academic",
  ]);
  type_adding_dict.set("Website", [
    "Website",
    "Structure",
    "Canvas",
    "Player",
    "SR Characters",
  ]);
}

export default WebsiteNode;

import { SetStateAction } from "react";
import { Card, CardHeader, Button, CardBody, Col, Row } from "reactstrap";
import { Canvas } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
import { ReactGlobals, TreeNode } from "../Types";
import backendService from "../../services/backend.service";
import { parseShapeGeometry } from "./ThreeParsers";
import { argNumberGUI } from "./ThreeGUIElements";

const ShapeGeometryNode = (
  node: TreeNode,
  globals: ReactGlobals,
  load_node: (new_id: string) => void,
  handleTempContentChange: (event: {
    target: { value: SetStateAction<string> };
  }) => void
) => {
  let material = <meshStandardMaterial wireframe={true} />;
  let shapesArray: any[] = [];

  let geometry =
    node.ID === globals.node.ID
      ? parseShapeGeometry(globals.temporaryContent)
      : parseShapeGeometry(node.Content);

  try {
    const new_args =
      node.ID === globals.node.ID
        ? JSON.parse(globals.temporaryContent)
        : JSON.parse(node.Content);

    let new_shapes = new_args["shapes"];
    if (Array.isArray(new_shapes)) {
      shapesArray = new_shapes;
    }
  } catch (e) {
    console.log("error parsing json");
  }

  function save_geometry() {
    let json = {
      shapes: shapesArray,
      curveSegments: geometry.props.args[1],
    };
    const text = JSON.stringify(json);

    node.ID === globals.node.ID
      ? globals.setTemporaryContent(text)
      : backendService.change_content(node.ID, text).then((res) => {
          load_node(globals.node.ID);
        });
  }

  return (
    <Card key={Math.random().toString() + node.ID}>
      <CardHeader>
        <Button
          outline
          disabled={node.ID === globals.node.ID}
          onClick={() => {
            load_node(node.ID);
          }}
        >
          {node.ID === globals.node.ID ? globals.temporaryName : node.Name}
        </Button>
      </CardHeader>
      <CardBody>
        <Row>
          <Col xs="auto">
            <div
              style={{
                width: "200px",
                height: "200px",
              }}
            >
              <Canvas camera={{ position: [0.0, 1.5, 1.5] }}>
                <ambientLight />
                <pointLight
                  position={[5.0, 5.0, 5.0]}
                  decay={0.1}
                  intensity={2.0}
                />
                <mesh>
                  {geometry}
                  {material}
                </mesh>
                <OrbitControls makeDefault />
              </Canvas>
            </div>
          </Col>
          <Col>
            {argNumberGUI(
              "curveSegments",
              1,
              200,
              1,
              save_geometry,
              geometry.props.args,
              1,
              globals
            )}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export function add_ShapeGeometry_to_dicts(
  constuctor_dict: Map<
    string,
    (
      node: TreeNode,
      globals: ReactGlobals,
      load_node: (new_id: string) => void,
      handleTempContentChange: (event: {
        target: { value: SetStateAction<string> };
      }) => void
    ) => JSX.Element
  >,
  type_prompt_dict: Map<string, string[]>,
  type_adding_dict: Map<string, string[]>
) {
  constuctor_dict.set("ShapeGeometry", ShapeGeometryNode);
  type_prompt_dict.set("ShapeGeometry", []);
  type_adding_dict.set("ShapeGeometry", []);
}

export default ShapeGeometryNode;

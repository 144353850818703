import { SetStateAction } from "react";
import { Card, CardHeader, Button, CardBody, Col, Row } from "reactstrap";
import { Canvas } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
import { ReactGlobals, TreeNode } from "../Types";
import backendService from "../../services/backend.service";
import { parseCapsuleGeometry } from "./ThreeParsers";
import { argNumberGUI } from "./ThreeGUIElements";

const CapsuleGeometryNode = (
  node: TreeNode,
  globals: ReactGlobals,
  load_node: (new_id: string) => void,
  handleTempContentChange: (event: {
    target: { value: SetStateAction<string> };
  }) => void
) => {
  let material = <meshStandardMaterial wireframe={true} />;

  let geometry =
    node.ID === globals.node.ID
      ? parseCapsuleGeometry(globals.temporaryContent)
      : parseCapsuleGeometry(node.Content);

  function save_geometry() {
    let json = {
      radius: geometry.props.args[0],
      length: geometry.props.args[1],
      capSegments: geometry.props.args[2],
      radialSegments: geometry.props.args[3],
    };
    const text = JSON.stringify(json);

    node.ID === globals.node.ID
      ? globals.setTemporaryContent(text)
      : backendService.change_content(node.ID, text).then((res) => {
          load_node(globals.node.ID);
        });
  }

  return (
    <Card key={Math.random().toString() + node.ID}>
      <CardHeader>
        <Button
          outline
          disabled={node.ID === globals.node.ID}
          onClick={() => {
            load_node(node.ID);
          }}
        >
          {node.ID === globals.node.ID ? globals.temporaryName : node.Name}
        </Button>
      </CardHeader>
      <CardBody>
        <Row>
          <Col xs="auto">
            <div
              style={{
                width: "200px",
                height: "200px",
              }}
            >
              <Canvas camera={{ position: [0.0, 1.5, 1.5] }}>
                <ambientLight />
                <pointLight
                  position={[5.0, 5.0, 5.0]}
                  decay={0.1}
                  intensity={2.0}
                />
                <mesh>
                  {geometry}
                  {material}
                </mesh>
                <OrbitControls makeDefault />
              </Canvas>
            </div>
          </Col>
          <Col>
            {argNumberGUI(
              "radius",
              0.0,
              50.0,
              0.001,
              save_geometry,
              geometry.props.args,
              0,
              globals
            )}
            {argNumberGUI(
              "length",
              0.0,
              100.0,
              0.001,
              save_geometry,
              geometry.props.args,
              1,
              globals
            )}
            {argNumberGUI(
              "capSegments",
              1,
              50,
              1,
              save_geometry,
              geometry.props.args,
              2,
              globals
            )}
            {argNumberGUI(
              "radialSegments",
              1,
              64,
              1,
              save_geometry,
              geometry.props.args,
              3,
              globals
            )}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export function add_CapsuleGeometry_to_dicts(
  constuctor_dict: Map<
    string,
    (
      node: TreeNode,
      globals: ReactGlobals,
      load_node: (new_id: string) => void,
      handleTempContentChange: (event: {
        target: { value: SetStateAction<string> };
      }) => void
    ) => JSX.Element
  >,
  type_prompt_dict: Map<string, string[]>,
  type_adding_dict: Map<string, string[]>
) {
  constuctor_dict.set("CapsuleGeometry", CapsuleGeometryNode);
  type_prompt_dict.set("CapsuleGeometry", []);
  type_adding_dict.set("CapsuleGeometry", []);
}

export default CapsuleGeometryNode;

import { SetStateAction } from "react";
import { Canvas } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
import { ReactGlobals, TreeNode } from "../Types";
import {
  parseAmbientLight,
  parseMesh,
  parseGroup,
  parsePointLight,
  parseDirectionalLight,
} from "./ThreeParsers";
import { Card, CardHeader, Button, CardBody } from "reactstrap";

function parseNode(node: TreeNode, globals: ReactGlobals) {
  if (node.Type === "AmbientLight") return parseAmbientLight(node.Content);
  else if (node.Type === "PointLight") return parsePointLight(node.Content);
  else if (node.Type === "DirectionalLight")
    return parseDirectionalLight(node.Content);
  else if (node.Type === "Mesh") return parseMesh(node, globals);
  else if (node.Type === "Group") return parseGroup(node, globals);
  else return parseMesh(node, globals);
}

const child_node_types = [
  "AmbientLight",
  "PointLight",
  "DirectionalLight",
  "Mesh",
  "Group",
];

const CanvasNode = (
  node: TreeNode,
  globals: ReactGlobals,
  load_node: (new_id: string) => void,
  handleTempContentChange: (event: {
    target: { value: SetStateAction<string> };
  }) => void
) => {
  let width = "100%";
  let height = "600px";
  let shadows = false;

  let canvas_args = new Map<string, string | boolean>();
  canvas_args.set("width", width);
  canvas_args.set("height", height);
  canvas_args.set("shadows", shadows);

  try {
    const new_args =
      node.ID === globals.node.ID
        ? JSON.parse(globals.temporaryContent)
        : JSON.parse(node.Content);
    if (new_args.hasOwnProperty("width")) {
      canvas_args.set("width", new_args["width"]);
    }
    if (new_args.hasOwnProperty("height")) {
      canvas_args.set("height", new_args["height"]);
    }
    if (new_args.hasOwnProperty("shadows")) {
      canvas_args.set("shadows", new_args["shadows"]);
    }
  } catch (e) {
    console.log("error parsing json");
  }

  let new_width = canvas_args.get("width");
  if (typeof new_width === "string") {
    width = new_width;
  }

  let new_height = canvas_args.get("height");
  if (typeof new_height === "string") {
    height = new_height;
  }

  let new_shadows = canvas_args.get("shadows");
  if (typeof new_shadows === "boolean") {
    shadows = new_shadows;
  }

  return (
    <Card key={Math.random().toString() + node.ID}>
      <CardHeader>
        <Button
          outline
          disabled={node.ID === globals.node.ID}
          onClick={() => {
            load_node(node.ID);
          }}
        >
          {node.ID === globals.node.ID ? globals.temporaryName : node.Name}
        </Button>
      </CardHeader>
      <CardBody>
        <div
          style={{
            width: width,
            height: height,
          }}
        >
          <Canvas camera={{ position: [0.0, 5.0, 8.0] }} shadows={shadows}>
            {node.Children.filter((child: TreeNode) =>
              child_node_types.includes(child.Type)
            ).map((child: TreeNode) => {
              return parseNode(child, globals);
            })}
            <OrbitControls makeDefault />
          </Canvas>
        </div>

        {node.Children.filter((child: TreeNode) =>
          child_node_types.includes(child.Type)
        ).map((child: TreeNode) => (
          <Button
            key={Math.random().toString() + node.ID}
            outline
            style={{
              textAlign: "left",
            }}
            onClick={() => {
              load_node(child.ID);
            }}
          >
            {child.Name.length > 30
              ? child.Name.substring(0, 28) + "..."
              : child.Name}
          </Button>
        ))}
      </CardBody>
    </Card>
  );
};

export function add_Canvas_to_dicts(
  constuctor_dict: Map<
    string,
    (
      node: TreeNode,
      globals: ReactGlobals,
      load_node: (new_id: string) => void,
      handleTempContentChange: (event: {
        target: { value: SetStateAction<string> };
      }) => void
    ) => JSX.Element
  >,
  type_prompt_dict: Map<string, string[]>,
  type_adding_dict: Map<string, string[]>
) {
  constuctor_dict.set("Canvas", CanvasNode);
  type_prompt_dict.set("Canvas", []);
  type_adding_dict.set("Canvas", child_node_types);
}

export default CanvasNode;

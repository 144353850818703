import { InputGroup, InputGroupText, Input } from "reactstrap";
import { Vector3 } from "three";
import { ReactGlobals } from "../Types";

export function vector3GUI(
  name: string,
  vector: Vector3,
  min: number,
  max: number,
  save: () => void,
  globals: ReactGlobals
) {
  return (
    <>
      <InputGroup size="sm">
        <InputGroupText className="w-25">{name} X</InputGroupText>
        <Input
          disabled={!globals.isLoggedIn}
          style={{ textAlign: "right" }}
          defaultValue={vector.x}
          onChange={(e) => {
            vector.x = +e.currentTarget.value;
          }}
          onKeyUp={(e) => {
            if (e.key === "Enter") save();
          }}
        />
        <InputGroupText className="w-50">
          {globals.isLoggedIn ? (
            <Input
              name="range"
              type="range"
              min={min}
              max={max}
              step={0.001}
              defaultValue={vector.x}
              onChange={(e) => {
                if (+e.currentTarget.value !== vector.x) {
                  vector.x = +e.currentTarget.value;
                }
              }}
              onMouseUp={(e) => {
                save();
              }}
            />
          ) : (
            <></>
          )}
        </InputGroupText>
      </InputGroup>

      <InputGroup size="sm">
        <InputGroupText className="w-25">{name} Y</InputGroupText>
        <Input
          disabled={!globals.isLoggedIn}
          style={{ textAlign: "right" }}
          defaultValue={vector.y}
          onChange={(e) => {
            vector.y = +e.currentTarget.value;
          }}
          onKeyUp={(e) => {
            if (e.key === "Enter") save();
          }}
        />
        <InputGroupText className="w-50">
          {globals.isLoggedIn ? (
            <Input
              name="range"
              type="range"
              min={min}
              max={max}
              step={0.001}
              defaultValue={vector.y}
              onChange={(e) => {
                if (+e.currentTarget.value !== vector.y) {
                  vector.y = +e.currentTarget.value;
                }
              }}
              onMouseUp={(e) => {
                save();
              }}
            />
          ) : (
            <></>
          )}
        </InputGroupText>
      </InputGroup>

      <InputGroup size="sm">
        <InputGroupText className="w-25">{name} Z</InputGroupText>
        <Input
          disabled={!globals.isLoggedIn}
          style={{ textAlign: "right" }}
          defaultValue={vector.z}
          onChange={(e) => {
            vector.z = +e.currentTarget.value;
          }}
          onKeyUp={(e) => {
            if (e.key === "Enter") save();
          }}
        />
        <InputGroupText className="w-50">
          {globals.isLoggedIn ? (
            <Input
              name="range"
              type="range"
              min={min}
              max={max}
              step={0.001}
              defaultValue={vector.z}
              onChange={(e) => {
                if (+e.currentTarget.value !== vector.z) {
                  vector.z = +e.currentTarget.value;
                }
              }}
              onMouseUp={(e) => {
                save();
              }}
            />
          ) : (
            <></>
          )}
        </InputGroupText>
      </InputGroup>
    </>
  );
}

export function argNumberGUI(
  name: string,
  min: number,
  max: number,
  step: number,
  save: () => void,
  argsList: number[],
  argIndex: number,
  globals: ReactGlobals
) {
  return (
    <InputGroup size="sm">
      <InputGroupText className="w-25">{name}</InputGroupText>
      <Input
        disabled={!globals.isLoggedIn}
        style={{ textAlign: "right" }}
        defaultValue={argsList[argIndex]}
        onChange={(e) => {
          argsList[argIndex] = +e.currentTarget.value;
        }}
        onKeyUp={(e) => {
          if (e.key === "Enter") save();
        }}
      />
      <InputGroupText className="w-50">
        {globals.isLoggedIn ? (
          <Input
            name="range"
            type="range"
            min={min}
            max={max}
            step={step}
            defaultValue={argsList[argIndex]}
            onChange={(e) => {
              if (+e.currentTarget.value !== argsList[argIndex]) {
                argsList[argIndex] = +e.currentTarget.value;
              }
            }}
            onMouseUp={(e) => {
              save();
            }}
          />
        ) : (
          <></>
        )}
      </InputGroupText>
    </InputGroup>
  );
}

export function argBooleanGUI(
  name: string,
  save: () => void,
  argsList: boolean[],
  argIndex: number,
  globals: ReactGlobals
) {
  return (
    <InputGroup size="sm">
      <InputGroupText className="w-25">{name}</InputGroupText>
      <InputGroupText>
        <Input
          disabled={!globals.isLoggedIn}
          checked={argsList[argIndex]}
          type="checkbox"
          onChange={(e) => {
            argsList[argIndex] = e.currentTarget.checked;
            save();
          }}
        />
      </InputGroupText>
    </InputGroup>
  );
}

import { Input, Label } from "reactstrap";
import { ReactGlobals, TreeNode } from "../Types";
import backendService from "../../services/backend.service";
import { SetStateAction } from "react";

const RangedNumberNode = (
  node: TreeNode,
  globals: ReactGlobals,
  load_node: (new_id: string) => void,
  handleTempContentChange: (event: {
    target: { value: SetStateAction<string> };
  }) => void
) => {
  let min = 0;
  let max = 10;
  let step = 1;

  node.Children.forEach((child: TreeNode) => {
    if (child.Name === "min") {
      min = +child.Content;
    } else if (child.Name === "max") {
      max = +child.Content;
    } else if (child.Name === "step") {
      step = +child.Content;
    }
  });

  let id = Math.random().toString() + node.ID;

  return (
    <div key={Math.random().toString() + node.ID}>
      <Label size="sm" for={id}>
        {node.ID === globals.node.ID ? globals.temporaryName : node.Name}:{" "}
        {node.ID === globals.node.ID ? globals.temporaryContent : node.Content}{" "}
        / {max}
      </Label>
      {globals.isLoggedIn ? (
        <Input
          name="range"
          type="range"
          min={min}
          max={max}
          addon
          step={step}
          id={id}
          defaultValue={
            node.ID === globals.node.ID
              ? globals.temporaryContent
              : node.Content
          }
          onMouseUp={(e) => {
            if (e.currentTarget.value !== node.Content) {
              backendService
                .change_content(node.ID, "" + e.currentTarget.value)
                .then((res) => {
                  load_node(globals.node.ID);
                });
            }
          }}
        />
      ) : (
        <br />
      )}
    </div>
  );
};

export function add_RangedNumber_to_dicts(
  constuctor_dict: Map<
    string,
    (
      node: TreeNode,
      globals: ReactGlobals,
      load_node: (new_id: string) => void,
      handleTempContentChange: (event: {
        target: { value: SetStateAction<string> };
      }) => void
    ) => JSX.Element
  >,
  type_prompt_dict: Map<string, string[]>,
  type_adding_dict: Map<string, string[]>
) {
  constuctor_dict.set("RangedNumber", RangedNumberNode);
  type_prompt_dict.set("RangedNumber", []);
  type_adding_dict.set("RangedNumber", ["Number"]);
}

export default RangedNumberNode;

import { ChangeEvent } from "react";
import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import backendService from "../services/backend.service";
import { ReactGlobals } from "./Types";

const login_modal = (globals: ReactGlobals) => {
  return (
    <Modal
      isOpen={globals.openLoginModal}
      toggle={() => globals.setOpenLoginModal(!globals.openLoginModal)}
    >
      <ModalHeader
        toggle={() => globals.setOpenLoginModal(!globals.openLoginModal)}
      >
        Login
      </ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label for="username">Username:</Label>
          <Input
            type="text"
            name="username"
            onChange={(e: ChangeEvent) => {
              const target = e.target as HTMLInputElement;
              globals.setUsername(target.value);
            }}
            value={globals.username}
          />
        </FormGroup>

        <FormGroup>
          <Label for="password">Password:</Label>
          <Input
            type="password"
            name="password"
            onChange={(e: ChangeEvent) => {
              const target = e.target as HTMLInputElement;
              globals.setPassword(target.value);
            }}
            value={globals.password}
          />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={() => {
            backendService.log_in(
              globals.username,
              globals.password,
              globals.setIsLoggedIn
            );
            globals.setOpenLoginModal(!globals.openLoginModal);
            globals.setUsername("");
            globals.setPassword("");
          }}
        >
          Login
        </Button>{" "}
        <Button
          color="secondary"
          onClick={() => globals.setOpenLoginModal(!globals.openLoginModal)}
        >
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default login_modal;

import { SetStateAction } from "react";
import { Button, ButtonGroup, Col, Input, Row } from "reactstrap";
import { ReactGlobals, TreeNode } from "../Types";
import backendService from "../../services/backend.service";

const EditorNode = (
  node: TreeNode,
  globals: ReactGlobals,
  load_node: (new_id: string) => void,
  handleTempContentChange: (event: {
    target: { value: SetStateAction<string> };
  }) => void
) => {
  let dragSource = "";
  let target = "";
  let lastNewOrder = 0.0;

  return (
    <Row>
      <Col>
        <Input
          type="textarea"
          onChange={handleTempContentChange}
          value={globals.temporaryContent.toString()}
          rows={globals.node.Content.split(/\r\n|\r|\n/).length + 3}
        ></Input>
      </Col>
      <Col xs="auto">
        <ButtonGroup vertical>
          {globals.node.Children.map((child: TreeNode, child_index) => (
            <Button
              key={Math.random().toString() + child.ID}
              outline
              style={{
                textAlign: "left",
              }}
              draggable="true"
              onDragStart={() => {
                dragSource = child.ID;
              }}
              onDragEnd={() => {
                if (dragSource !== target) {
                  backendService.change_order(dragSource, lastNewOrder);
                  dragSource = "";
                  target = "";
                  lastNewOrder = 0.0;
                }
              }}
              onDragOver={(e) => {
                target = child.ID;
                let newOrder = 0.0;
                if (child_index === 0) {
                  newOrder = globals.node.Children[0].Order - 1.0;
                } else if (child_index === globals.node.Children.length - 1) {
                  newOrder =
                    globals.node.Children[globals.node.Children.length - 1]
                      .Order + 1.0;
                } else {
                  newOrder =
                    (globals.node.Children[child_index - 1].Order +
                      globals.node.Children[child_index].Order) *
                    0.5;
                }
                if (newOrder !== lastNewOrder) {
                  lastNewOrder = newOrder;
                }
              }}
              onClick={() => {
                load_node(child.ID);
              }}
            >
              {child.Name.length > 30
                ? child.Name.substring(0, 28) + "..."
                : child.Name}
            </Button>
          ))}
        </ButtonGroup>
      </Col>
    </Row>
  );
};
export default EditorNode;

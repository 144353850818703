import { Row } from "reactstrap";
import { TreeNode } from "../Types";

const UnknownTypeNode = (node: TreeNode) => {
  return (
    <Row key={Math.random().toString() + node.ID}>
      Unknown Type
      <br />
      <br /> ID: {node.ID}
      <br /> Name: {node.Name}
      <br /> Type: {node.Type}
      <br /> Content: {node.Content}
      <br /> Children: {node.Children.length}
      <br /> PathToRoot: {node.PathToRoot.length}
    </Row>
  );
};
export default UnknownTypeNode;

import { Button, ButtonGroup, InputGroup, InputGroupText } from "reactstrap";
import { ReactGlobals, TreeNode } from "../Types";
import backendService from "../../services/backend.service";
import { SetStateAction } from "react";

const NumberNode = (
  node: TreeNode,
  globals: ReactGlobals,
  load_node: (new_id: string) => void,
  handleTempContentChange: (event: {
    target: { value: SetStateAction<string> };
  }) => void
) => {
  let step = 1;

  node.Children.forEach((child: TreeNode) => {
    if (child.Name === "step") {
      step = +child.Content;
    }
  });

  return (
    <InputGroup key={Math.random().toString() + node.ID}>
      <ButtonGroup>
        {globals.isLoggedIn ? (
          <Button
            outline
            onClick={() => {
              backendService
                .change_content(node.ID, "" + (+node.Content - step))
                .then((res) => {
                  load_node(globals.node.ID);
                });
            }}
          >
            -{step}
          </Button>
        ) : (
          <></>
        )}
        <Button outline disabled>
          {node.ID === globals.node.ID
            ? globals.temporaryContent
            : node.Content}
        </Button>
        {globals.isLoggedIn ? (
          <Button
            outline
            onClick={() => {
              backendService
                .change_content(node.ID, "" + (+node.Content + step))
                .then((res) => {
                  load_node(globals.node.ID);
                });
            }}
          >
            +{step}
          </Button>
        ) : (
          <></>
        )}
      </ButtonGroup>
      <InputGroupText>
        {node.ID === globals.node.ID ? globals.temporaryName : node.Name}
      </InputGroupText>
    </InputGroup>
  );
};

export function add_Number_to_dicts(
  constuctor_dict: Map<
    string,
    (
      node: TreeNode,
      globals: ReactGlobals,
      load_node: (new_id: string) => void,
      handleTempContentChange: (event: {
        target: { value: SetStateAction<string> };
      }) => void
    ) => JSX.Element
  >,
  type_prompt_dict: Map<string, string[]>,
  type_adding_dict: Map<string, string[]>
) {
  constuctor_dict.set("Number", NumberNode);
  type_prompt_dict.set("Number", []);
  type_adding_dict.set("Number", ["Number"]);
}

export default NumberNode;

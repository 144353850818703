import {
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import ReconnectingWebSocket from "reconnecting-websocket";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import backendService from "../services/backend.service";
import {
  ReactGlobals,
  TreeNode,
  node_to_component,
  prompt_postfix_dict,
  prompt_prefix_dict,
  type_adding_dict,
} from "./Types";
import Editor from "./genericNodeTypes/Editor";
import { WEBSOCKET_URL } from "../constants";
import login_modal from "./Login";
import emoji_modal from "./EmojiPicker";
import user_header from "./UserHeader";
import node_header from "./NodeHeader";

const TypeRouter = () => {
  let { id, depth, pathmax } = useParams();
  if (id === undefined) {
    id = "77d5e6e8-e29e-495e-9baf-90486b2bbe7d";
  }
  if (depth === undefined) {
    depth = "3";
  }
  if (pathmax === undefined) {
    pathmax = "5";
  }

  const [node, setNode] = useState({
    ID: id,
    Name: "loading",
    Order: 0.0,
    Type: "loading",
    Content: "loading",
    Children: [] as TreeNode[],
    PathToRoot: [] as TreeNode[],
  });

  const navigate = useNavigate();

  function load_node(new_id: string) {
    backendService.get(new_id).then((res) => {
      const new_node = res.data;
      setNode(new_node);
      setTemporaryName(new_node.Name);
      setTemporaryContent(new_node.Content);

      const user = JSON.parse(localStorage.getItem("user") || "false");
      if (user) {
        setIsLoggedIn(true);
      }
    });
  }

  let header = (
    <Container>
      loading ... {id} - {depth} - {pathmax}
    </Container>
  );

  const [typeDropdownOpen, setTypeDropdownOpen] = useState<boolean>(false);
  const [addDropdownOpen, setAddDropdownOpen] = useState<boolean>(false);
  const [promptDropdownOpen, setPromptDropdownOpen] = useState<boolean>(false);
  const [temporaryName, setTemporaryName] = useState<string>(node.Name);
  const [realUserName, setRealUserName] = useState<string>("");
  const [editMode, setEditMode] = useState<boolean>(false);
  const [temporaryContent, setTemporaryContent] = useState<string>(
    node.Content
  );
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [isWaitingForAI, setIsWaitingForAI] = useState<boolean>(false);
  const [openLoginModal, setOpenLoginModal] = useState<boolean>(false);
  const [openEmojiModal, setOpenEmojiModal] = useState<boolean>(false);
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const socketRef = useRef<ReconnectingWebSocket | null>(null);

  let add_list = type_adding_dict.get(node.Type);
  if (add_list === undefined) {
    add_list = [];
  }

  const handleNameInputChange = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    let new_name = event.target.value;
    setTemporaryName(new_name);
  };

  const handleTempContentChange = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    let new_content = event.target.value;
    setTemporaryContent(new_content);
  };

  useEffect(() => {
    navigate("/node/" + node.ID + "/3/2");
    setTemporaryContent(node.Content);
  }, [node, navigate, setTemporaryContent]);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user") || "false");
    if (user && isLoggedIn) {
      setRealUserName(user["name"]);
    } else {
      if (editMode) {
        setEditMode(!editMode);
      }
      setRealUserName("");
    }
  }, [isLoggedIn, editMode, setEditMode]);

  useEffect(() => {
    socketRef.current = new ReconnectingWebSocket(
      WEBSOCKET_URL + "data_updates/" + id
    );

    socketRef.current.onopen = () => {
      setIsConnected(true);
    };

    socketRef.current.onclose = () => {
      setIsConnected(false);
    };

    socketRef.current.onmessage = (event) => {
      try {
        const new_node = JSON.parse(event.data);
        if ("new_content" in new_node) {
          setIsWaitingForAI(false);
          if (new_node["id"] === id) {
            setTemporaryContent(new_node["new_content"]);
          }
        } else {
          setNode(new_node);
          setTemporaryName(new_node.Name);
          setTemporaryContent(new_node.Content);
        }
      } catch (error) {
        console.error("Error parsing WebSocket message:", error);
      }
    };

    socketRef.current.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    return () => {
      if (socketRef.current) {
        socketRef.current.close();
      }
    };
  }, [id]);

  function send_prompt(prompt_name: string) {
    if (prompt_name.substring(0, "Translate to ".length) === "Translate to ") {
      let text = "" + prompt_name + ":\n \n" + temporaryContent;
      socketRef.current?.send(
        JSON.stringify({ id: node.ID, type: "content", prompt: text })
      );
      setIsWaitingForAI(true);
    } else {
      let text =
        "" +
        prompt_prefix_dict.get(prompt_name) +
        "\n \n" +
        temporaryContent +
        "\n \n" +
        prompt_postfix_dict.get(prompt_name);
      socketRef.current?.send(
        JSON.stringify({ id: node.ID, type: "content", prompt: text })
      );
      setIsWaitingForAI(true);
    }
  }

  let globals: ReactGlobals = {
    node,
    typeDropdownOpen,
    addDropdownOpen,
    promptDropdownOpen,
    temporaryName,
    temporaryContent,
    realUserName,
    editMode,
    isLoggedIn,
    isConnected,
    isWaitingForAI,
    openLoginModal,
    openEmojiModal,
    username,
    password,
    setNode,
    setTypeDropdownOpen,
    setAddDropdownOpen,
    setPromptDropdownOpen,
    setTemporaryName,
    setTemporaryContent,
    setRealUserName,
    setEditMode,
    setIsLoggedIn,
    setIsConnected,
    setIsWaitingForAI,
    setOpenLoginModal,
    setOpenEmojiModal,
    setUsername,
    setPassword,
  };

  if (node) {
    header = node_header(globals, load_node, handleNameInputChange);
  }

  const load_initial = useCallback(() => {
    load_node(id!);
  }, [id]);

  useEffect(() => {
    load_initial();
  }, [load_initial]);

  return (
    <Container style={{ marginTop: "20px" }}>
      <ToastContainer />
      {login_modal(globals)}
      {emoji_modal(globals, send_prompt)}
      {user_header(globals, send_prompt, load_node)}
      {header}
      <Row>
        <Col style={{ margin: "5px" }}>
          {editMode
            ? Editor(node, globals, load_node, handleTempContentChange)
            : node_to_component(
                node,
                globals,
                load_node,
                handleTempContentChange
              )}
        </Col>
      </Row>
    </Container>
  );
};

export default TypeRouter;

import { SetStateAction } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  InputGroup,
  InputGroupText,
  Row,
} from "reactstrap";
import { Vector3 } from "three";
import { Canvas } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
import { ReactGlobals, TreeNode } from "../Types";
import backendService from "../../services/backend.service";
import { parseArrayToVec3, parseMesh } from "./ThreeParsers";
import { vector3GUI } from "./ThreeGUIElements";

const child_node_types = [
  "BoxGeometry",
  "CapsuleGeometry",
  "ConeGeometry",
  "CylinderGeometry",
  "PlaneGeometry",
  "ShapeGeometry",
  "SphereGeometry",
  "TorusGeometry",
  "MeshStandardMaterial",
  "Mesh",
  "Group",
];

const MeshNode = (
  node: TreeNode,
  globals: ReactGlobals,
  load_node: (new_id: string) => void,
  handleTempContentChange: (event: {
    target: { value: SetStateAction<string> };
  }) => void
) => {
  let mesh =
    node.ID === globals.node.ID
      ? parseMesh(node, globals)
      : parseMesh(node, globals);

  let rotation = new Vector3(0, 0, 0);
  let castShadow = true;
  let receiveShadow = true;

  try {
    const new_args =
      node.ID === globals.node.ID
        ? JSON.parse(globals.temporaryContent)
        : JSON.parse(node.Content);

    if (new_args.hasOwnProperty("rotation")) {
      rotation = parseArrayToVec3(new_args["rotation"]);
    }

    if (new_args.hasOwnProperty("castShadow")) {
      castShadow = new_args["castShadow"];
    }

    if (new_args.hasOwnProperty("receiveShadow")) {
      receiveShadow = new_args["receiveShadow"];
    }
  } catch (e) {
    console.log("error parsing json", e);
  }

  function save_node() {
    let json = {
      position: [
        mesh.props.position.x,
        mesh.props.position.y,
        mesh.props.position.z,
      ],
      rotation: [rotation.x, rotation.y, rotation.z],
      scale: [mesh.props.scale.x, mesh.props.scale.y, mesh.props.scale.z],
      castShadow: castShadow,
      receiveShadow: receiveShadow,
    };
    const text = JSON.stringify(json);

    node.ID === globals.node.ID
      ? globals.setTemporaryContent(text)
      : backendService.change_content(node.ID, text);
  }

  return (
    <Card key={Math.random().toString() + node.ID}>
      <CardHeader>
        <Button
          outline
          disabled={node.ID === globals.node.ID}
          onClick={() => {
            load_node(node.ID);
          }}
        >
          {node.ID === globals.node.ID ? globals.temporaryName : node.Name}
        </Button>
      </CardHeader>
      <CardBody>
        <Row>
          <Col xs="auto">
            <div
              style={{
                width: "200px",
                height: "200px",
              }}
            >
              <Canvas camera={{ position: [0.0, 1.5, 15] }}>
                <ambientLight />
                <pointLight
                  position={[5.0, 5.0, 5.0]}
                  decay={0.1}
                  intensity={2.0}
                />
                {mesh}
                <OrbitControls makeDefault />
              </Canvas>
            </div>
          </Col>
          <Col>
            <InputGroup size="sm">
              <InputGroupText className="w-25">castShadow</InputGroupText>
              <InputGroupText>
                <Input
                  disabled={!globals.isLoggedIn}
                  checked={castShadow}
                  type="checkbox"
                  onChange={(e) => {
                    castShadow = e.currentTarget.checked;
                    save_node();
                  }}
                />
              </InputGroupText>
            </InputGroup>
            <InputGroup size="sm">
              <InputGroupText className="w-25">receiveShadow</InputGroupText>
              <InputGroupText>
                <Input
                  disabled={!globals.isLoggedIn}
                  checked={receiveShadow}
                  type="checkbox"
                  onChange={(e) => {
                    receiveShadow = e.currentTarget.checked;
                    save_node();
                  }}
                />
              </InputGroupText>
            </InputGroup>
            {vector3GUI(
              "position",
              mesh.props.position,
              -100.0,
              100.0,
              save_node,
              globals
            )}
            {vector3GUI(
              "rotation",
              rotation,
              -Math.PI,
              Math.PI,
              save_node,
              globals
            )}
            {vector3GUI(
              "scale",
              mesh.props.scale,
              0.0,
              100.0,
              save_node,
              globals
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            {node.Children.filter((child: TreeNode) =>
              child_node_types.includes(child.Type)
            ).map((child: TreeNode) => (
              <Button
                key={Math.random().toString() + node.ID}
                outline
                style={{
                  textAlign: "left",
                }}
                onClick={() => {
                  load_node(child.ID);
                }}
              >
                {child.Name.length > 30
                  ? child.Name.substring(0, 28) + "..."
                  : child.Name}
              </Button>
            ))}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export function add_Mesh_to_dicts(
  constuctor_dict: Map<
    string,
    (
      node: TreeNode,
      globals: ReactGlobals,
      load_node: (new_id: string) => void,
      handleTempContentChange: (event: {
        target: { value: SetStateAction<string> };
      }) => void
    ) => JSX.Element
  >,
  type_prompt_dict: Map<string, string[]>,
  type_adding_dict: Map<string, string[]>
) {
  constuctor_dict.set("Mesh", MeshNode);
  type_prompt_dict.set("Mesh", []);
  type_adding_dict.set("Mesh", child_node_types);
}

export default MeshNode;

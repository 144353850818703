import { Dispatch, SetStateAction } from "react";

import UnknownTypeNode from "./genericNodeTypes/UnknownType";
import { add_Website_to_dicts } from "./genericNodeTypes/Website";
import { add_Structure_to_dicts } from "./genericNodeTypes/Structure";
import { add_Bool_to_dicts } from "./genericNodeTypes/Bool";
import { add_Number_to_dicts } from "./genericNodeTypes/Number";
import { add_RangedNumber_to_dicts } from "./genericNodeTypes/RangedNumber";
import { add_Color_to_dicts } from "./genericNodeTypes/Color";
import { add_Player_to_dicts } from "./genericNodeTypes/Player";

import { add_SRCharacter_to_dicts } from "./usecaseNodeTypes/Shadowrun/SRCharacter";
import { add_SRCharacters_to_dicts } from "./usecaseNodeTypes/Shadowrun/SRCharacters";

import { add_Canvas_to_dicts } from "./threeNodeTypes/Canvas";
import { add_MeshStandardMaterial_to_dicts } from "./threeNodeTypes/MeshStandardMaterial";
import { add_AmbientLight_to_dicts } from "./threeNodeTypes/AmbientLight";
import { add_PointLight_to_dicts } from "./threeNodeTypes/PointLight";
import { add_DirectionalLight_to_dicts } from "./threeNodeTypes/DirectionalLight";
import { add_Group_to_dicts } from "./threeNodeTypes/Group";
import { add_Mesh_to_dicts } from "./threeNodeTypes/Mesh";
import { add_BoxGeometry_to_dicts } from "./threeNodeTypes/BoxGeometry";
import { add_SphereGeometry_to_dicts } from "./threeNodeTypes/SphereGeometry";
import { add_ConeGeometry_to_dicts } from "./threeNodeTypes/ConeGeometry";
import { add_CylinderGeometry_to_dicts } from "./threeNodeTypes/CylinderGeometry";
import { add_PlaneGeometry_to_dicts } from "./threeNodeTypes/PlaneGeometry";
import { add_CapsuleGeometry_to_dicts } from "./threeNodeTypes/CapsuleGeometry";
import { add_TorusGeometry_to_dicts } from "./threeNodeTypes/TorusGeometry";
import { add_ShapeGeometry_to_dicts } from "./threeNodeTypes/ShapeGeometry";

type TreeNode = {
  ID: string;
  Name: string;
  Order: number;
  Type: string;
  Content: string;
  Children: TreeNode[];
  PathToRoot: TreeNode[];
};

export const constuctor_dict = new Map<
  string,
  (
    node: TreeNode,
    globals: ReactGlobals,
    load_node: (new_id: string) => void,
    handleTempContentChange: (event: {
      target: { value: SetStateAction<string> };
    }) => void
  ) => JSX.Element
>();
export const type_prompt_dict = new Map<string, string[]>();
export const type_adding_dict = new Map<string, string[]>();

add_Website_to_dicts(constuctor_dict, type_prompt_dict, type_adding_dict);
add_Structure_to_dicts(constuctor_dict, type_prompt_dict, type_adding_dict);
add_Bool_to_dicts(constuctor_dict, type_prompt_dict, type_adding_dict);
add_Number_to_dicts(constuctor_dict, type_prompt_dict, type_adding_dict);
add_RangedNumber_to_dicts(constuctor_dict, type_prompt_dict, type_adding_dict);
add_Color_to_dicts(constuctor_dict, type_prompt_dict, type_adding_dict);
add_Player_to_dicts(constuctor_dict, type_prompt_dict, type_adding_dict);

add_SRCharacter_to_dicts(constuctor_dict, type_prompt_dict, type_adding_dict);
add_SRCharacters_to_dicts(constuctor_dict, type_prompt_dict, type_adding_dict);

add_Canvas_to_dicts(constuctor_dict, type_prompt_dict, type_adding_dict);
add_MeshStandardMaterial_to_dicts(
  constuctor_dict,
  type_prompt_dict,
  type_adding_dict
);
add_AmbientLight_to_dicts(constuctor_dict, type_prompt_dict, type_adding_dict);
add_PointLight_to_dicts(constuctor_dict, type_prompt_dict, type_adding_dict);
add_DirectionalLight_to_dicts(
  constuctor_dict,
  type_prompt_dict,
  type_adding_dict
);

add_Group_to_dicts(constuctor_dict, type_prompt_dict, type_adding_dict);
add_Mesh_to_dicts(constuctor_dict, type_prompt_dict, type_adding_dict);

add_BoxGeometry_to_dicts(constuctor_dict, type_prompt_dict, type_adding_dict);
add_SphereGeometry_to_dicts(
  constuctor_dict,
  type_prompt_dict,
  type_adding_dict
);
add_ConeGeometry_to_dicts(constuctor_dict, type_prompt_dict, type_adding_dict);
add_CylinderGeometry_to_dicts(
  constuctor_dict,
  type_prompt_dict,
  type_adding_dict
);
add_PlaneGeometry_to_dicts(constuctor_dict, type_prompt_dict, type_adding_dict);
add_CapsuleGeometry_to_dicts(
  constuctor_dict,
  type_prompt_dict,
  type_adding_dict
);
add_TorusGeometry_to_dicts(constuctor_dict, type_prompt_dict, type_adding_dict);
add_ShapeGeometry_to_dicts(constuctor_dict, type_prompt_dict, type_adding_dict);

export const prompt_prefix_dict = new Map<string, string>();
export const prompt_postfix_dict = new Map<string, string>();

prompt_prefix_dict.set(
  "Improve",
  "Improve the following description and use markdown notation: "
);
prompt_postfix_dict.set("Improve", "");

prompt_prefix_dict.set("More casual", "Make the following text more casual: ");
prompt_postfix_dict.set("More casual", "");

prompt_prefix_dict.set("More formal", "Make the following text more formal: ");
prompt_postfix_dict.set("More formal", "");

prompt_prefix_dict.set(
  "More academic",
  "Make the following text more academic: : "
);
prompt_postfix_dict.set("More academic", "");

prompt_prefix_dict.set(
  "Summarize",
  "Summarize the following description with markdown notation: "
);
prompt_postfix_dict.set("Summarize", "");

prompt_prefix_dict.set(
  "Image",
  "Create an evocative image to go with this description: "
);
prompt_postfix_dict.set("Image", "");

prompt_prefix_dict.set(
  "Improve Description",
  "Improve the following description and use markdown notation: "
);
prompt_postfix_dict.set("Improve Description", "");

prompt_prefix_dict.set(
  "Portrait",
  "Create a portrait image to go with this character description: "
);
prompt_postfix_dict.set("Portrait", "");

export const node_to_component = (
  node: TreeNode,
  globals: ReactGlobals,
  load_node: (new_id: string) => void,
  handleTempContentChange: (event: {
    target: { value: SetStateAction<string> };
  }) => void
) => {
  const constructor = constuctor_dict.get(node.Type);
  if (constructor !== undefined) {
    return constructor(node, globals, load_node, handleTempContentChange);
  } else {
    return UnknownTypeNode(node);
  }
};

type ReactGlobals = {
  node: TreeNode;
  typeDropdownOpen: boolean;
  addDropdownOpen: boolean;
  promptDropdownOpen: boolean;
  temporaryName: string;
  temporaryContent: string;
  realUserName: string;
  editMode: boolean;
  isLoggedIn: boolean;
  isConnected: boolean;
  isWaitingForAI: boolean;
  openLoginModal: boolean;
  openEmojiModal: boolean;
  username: string;
  password: string;
  setNode: Dispatch<SetStateAction<TreeNode>>;
  setTypeDropdownOpen: Dispatch<SetStateAction<boolean>>;
  setAddDropdownOpen: Dispatch<SetStateAction<boolean>>;
  setPromptDropdownOpen: Dispatch<SetStateAction<boolean>>;
  setTemporaryName: Dispatch<SetStateAction<string>>;
  setTemporaryContent: Dispatch<SetStateAction<string>>;
  setRealUserName: Dispatch<SetStateAction<string>>;
  setEditMode: Dispatch<SetStateAction<boolean>>;
  setIsLoggedIn: Dispatch<SetStateAction<boolean>>;
  setIsConnected: Dispatch<SetStateAction<boolean>>;
  setIsWaitingForAI: Dispatch<SetStateAction<boolean>>;
  setOpenLoginModal: Dispatch<SetStateAction<boolean>>;
  setOpenEmojiModal: Dispatch<SetStateAction<boolean>>;
  setUsername: Dispatch<SetStateAction<string>>;
  setPassword: Dispatch<SetStateAction<string>>;
};

export type { TreeNode, ReactGlobals };
